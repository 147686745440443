<template>
  <div id="app">
    
    <MobileHeader v-if="isMobile"></MobileHeader>
    <PcHeader v-else></PcHeader>
    <router-view :isMobile="isMobile"/>
  </div>
</template>

<script>
import PcHeader from '@/components/pc-header.vue'
import MobileHeader from '@/components/mobile-header.vue'
export default {
  name: "App",
  components: {
    PcHeader,
    MobileHeader
  },
  data () {
    return {
      isMobile: false
    }
  },
  mounted () {
    /*
      *
      * 判断PC端与WAP端
      */
    var mobile_bs = {
      versions: function() {
          var u = navigator.userAgent;
          console.log(u, 'u')
          return {
            trident: u.indexOf('Trident') > -1, //IE内核
            presto: u.indexOf('Presto') > -1, //opera内核
            webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
            mobile: !! u.match(/Android.*/) || !!u.match(/AppleWebKit.*Mobile.*/) || !! u.match(/AppleWebKit/) && u.indexOf('QIHU') && u.indexOf('QIHU') > -1 && u.indexOf('Chrome') < 0, //是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
            android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或者uc浏览器
            iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1, //是否为iPhone或者QQHD浏览器
            iPad: u.indexOf('iPad') > -1, //是否iPad
            webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
          }
        } ()
      };
      // console.log(mobile_bs.versions.mobile, 'mobile_bs.versions.mobile')
    if (mobile_bs.versions.mobile) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
  }
};
</script>

<style>
#app {
  width: 100%;
  margin: 0 auto;
  font-family: Adonais;
  font-size: 0.7rem;
  overflow-x: hidden;
  background-color: #ffffff;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
</style>
