import img41 from '../../assets/images/faq/4-1.png'
import img51 from '../../assets/images/faq/5-1.png'
import img52 from '../../assets/images/faq/5-2.png'
import img151 from '../../assets/images/faq/15-1.png'
const message = {
  index: {
    pageName: 'INDEKS',
    gamefeatures: 'FITUR PERMAINAN'
  },
  shop: {
    pageName: 'TOKO',
  },
  faq: {
    pageName: 'SSD',
    faqList: [
      {
        id: 1, 
        title: "Bidikan naga secara manual membuatku lelah. Apakah ada cara yang lebih mudah untuk bermain?",
        desc: "Ya. Anda dapat memilih untuk menggunakan pertarungan otomatis dalam berbagai mode pertempuran. Ini akan membuat naga Anda memilih target secara otomatis dan berpindah ketika target terbunuh. Anda masih dapat memilih target sendiri untuk menghadapi musuh dengan ancaman tinggi dan tidak akan mengganggu mode pertarungan otomatis. Jangan lupa untuk mengeluarkan mantra, mereka masih membutuhkan Anda untuk menggerakan secara manual. Anda dapat membuka fungsi ini setelah menyelesaikan Windland area 2 level 4."
      },
      {
        id: 2, 
        title: "Fungsi mudah apa yang dapat dibuka untuk mempercepat permainan saya?",
        desc: "Kecuali pertarungan otomatis dapat dibuka dari hadiah level. Anda dapat membuka sebagian besar fitur lainnya dari hadiah level VIP. Termasuk tantangan cepat menjarah, percepat pertempuran, dan tantangan cepat Bos Dunia."
      },
      {
        id: 3,
        title: 'Saya sudah memilih target tetapi naga saya tidak memberikan kerusakan apapun pada target.',
        desc: "Serangan naga sebenarnya balistik. Jadi, jika ada musuh lain (seperti mesin pengepungan) di depan target, itu akan memblokir serangan naga Anda. Untuk mengatasi masalah ini, coba gunakan lebih banyak serangan area mungkin akan lebih bermanfaat."
      },
      {
        id: 4,
        title: "Spamming tombol beli menara kurang efisien. Bagaimana saya bisa meningkatkannya?",
        desc: "Anda dapat memilih bintang menara yang ingin Anda beli. Tekan tombol bintang di kanan bawah halaman ubah menara, lalu Anda dapat memilih. Bintang awal yang lebih tinggi membutuhkan Anda untuk membukanya dengan mendapatkan menara bintang tinggi. Anda dapat menekannya untuk melihat persyaratan membuka. (Posisi pintu masuk ada di lingkaran merah pada gambar di bawah）",
        img: [
          img41
        ]
      },
      {
        id: 5,
        title: "Saya memiliki terlalu banyak menara yang tidak berguna. Bagaimana saya bisa membersihkannya?",
        desc: "Anda dapat mendaur ulangnya dengan menggabungkan untuk menara berkualitas lebih tinggi. Tekan tombol tanda daur ulang di kanan tengah halaman ubah menara untuk masuk ke halaman penggabungan. Anda dapat melihat probabilitas hasilnya jika Anda memilih tiga menara yang memiliki bintang berbeda. Dan Anda dapat menekan tombol tanda tanya untuk melihat lebih banyak petunjuk. (Posisi pintu masuk ada di lingkaran merah pada gambar di bawah)",
        img: [
          img51,
          img52
        ]
      },
      {
        id: 6,
        title: "Mengapa saya bisa dikalahkan oleh pemain lain yang lebih lemah di arena atau perang pulau liga",
        desc: "Hasil pertempuran tidak hanya tergantung pada jumlah kekuatan. Itu juga akan dipengaruhi oleh kombinasi antara Anda dan pemain lain. Contohnya, beberapa pemain yang sangat berinvestasi di beberapa bagian tim mereka mungkin membuat mereka terlihat lemah, tetapi memiliki kekuatan untuk melawan pemain dengan kekuatan lebih tinggi."
      },
      {
        id: 701, // 补充的一条
        title: "Bagaimana cara menghadapi pemain yang curang di arena atau bos dunia?",
        desc: 'Anda dapat melaporkannya ke tim dukungan kami. Anda dapat menemukan pemain yang ditipu di halaman catatan di arena dan di halaman peringkat di bos dunia. Kemudian tekan "laporkan" untuk mengirimkan email kepada kami. Jika kami mengkonfirmasi ada perilaku kecurangan, kami akan memperingatkan pemain yang curang tersebut. Jika dia terus melakukan kecurangan, maka akunnya akan diblokir 3 hari. Dan hukumannya akan terus bertambah jika dia terus melakukan kecurangan.'
      },
      {
        id: 7,
        title: "Saya meningkatkan istana saya tetapi produksi sumber daya saya tidak meningkat.",
        desc: "Peningkatan istana hanya akan meningkatkan kapasitas penyimpanan masing-masing sumber daya. Untuk meningkatkan produksi sumber daya, Anda harus memainkan mode kebuntuan portal untuk mendapatkan lebih banyak bangunan yang dapat ditempatkan di pulau Anda dan tingkatkan bangunan tersebut."
      },
      {
        id: 8,
        title: "Ketua liga kami tidak masuk ke dalam permainan selama beberapa hari dan kami tidak dapat meluncurkan perang pulau liga.",
        desc: "Untuk saat ini, setelah sepuluh hari tidak masuk, ketua liga akan dikeluarkan secara otomatis. Kami merencanakan sistem voting pengeluaran untuk memperbaiki masalah ini."
      },
      {
        id: 9,
        title: "Apakah progres saya di kamp pelatihan akan hilang ketika saya keluar dari liga?",
        desc: "Tidak, tidak akan. Level kamp pelatihan terkait dengan Anda, bukan liga dan semua orang memiliki progres mereka sendiri. Tetapi Anda akan kehilangan buff-nya hingga Anda bergabung dengan liga berikutnya."
      },
      {
        id: 10,
        title: "Mengapa hasil suplai pulau untuk diklaim terkadang lebih tinggi dari total hasil saya?",
        desc: "Jika liga Anda telah menaklukkan pulau baru, hasil suplai untuk diklaim akan segera meningkat. Tapi total hasilnya tidak akan meningkat."
      },
      {
        id: 11,
        title: "Jika saya bergabung/keluar liga selama perang pulau liga. Apa akibatnya?",
        desc: "Saat Anda bergabung/keluar dari liga, Anda tidak akan dapat bergabung dengan perang pulau liga jika sudah dimulai dan tidak akan menerima hadiah apapun darinya."
      },
      {
        id: 12,
        title: "Bagaimana cara meningkatkan peluang memenangkan perang pulau liga?",
        desc: "Pemenang perang pulau liga ditentukan oleh skor yang diperoleh liga Anda. Jadi kekuatan bukanlah satu-satunya elemen kemenangan namun adalah usaha. Jika liga Anda dapat fokus pada penggunaan Rum (stamina perang pulau liga) dan menaklukkan pulau-pulau yang memberikan skor lebih banyak maka akan meningkatkan peluang Anda untuk menang."
      },
      {
        id: 13,
        title: "Bagaimana saya bisa menghubungi tim dukungan game jika saya menemui masalah? Berapa lama saya akan menerima balasan?",
        desc: "<p>Anda dapat menggunakan umpan balik dalam game untuk mengirim email kepada kami. Tombolnya ada di menu pengaturan game. Kami akan membalas Anda dalam waktu 24 jam, tergantung pada zona waktu dan hal lainnya. Anda juga dapat menghubungi kami melalui cara-cara di bawah ini.</p><p>Facebook: <a href='https://www.facebook.com/NorthTowerGame'>https://www.facebook.com/NorthTowerGame</a></p><p>Twitter: <a href='https://twitter.com/NorthTowerGame'>https://twitter.com/NorthTowerGame</a></p>"
      },
      {
        id: 14,
        title: "Saya tidak sengaja membeli paket yang salah di dalam game. Bagaimana saya bisa mendapatkan pengembalian dana?",
        desc: "Anda dapat segera meminta pengembalian dana dari tim dukungan Google Play. Dalam waktu dua jam dan tidak lebih dari 48 jam setelah pembelian, biasanya tidak akan menyebabkan permintaan pengembalian dana Anda gagal."
      },
      {
        id: 15,
        title: "Saya memasuki game dengan bahasa yang salah. Di mana saya bisa mengubahnya?",
        desc: "Anda dapat mengubah bahasa game Anda di menu pengaturan. (Posisi pintu masuk ada di lingkaran merah pada gambar di bawah.)",
        img: [img151]
      },
      {
        id: 16,
        title: "Ada lag atau gangguan pada permainan saya.",
        desc: "Masalah koneksi mungkin penyebabnya atau ponsel Anda dalam beban tinggi. Silakan periksa atau ubah koneksi Anda. Anda dapat menurunkan tingkat grafik dan efek di menu pengaturan."
      },
      {
        id: 17,
        title: "Bagaimana saya harus menautkan akun saya dengan akun Google saya dan mengapa saya harus melakukannya?",
        desc: "Anda dapat masuk ke menu pengaturan setelah Anda menyelesaikan beberapa tutorial. Ini penting karena jika Anda berpindah perangkat atau mencopot pemasangan game, data akun Anda akan hilang. Harap tautkan akun untuk melindungi data game Anda. Atau setidaknya ingat lord ID Anda yang muncul di menu pengaturan jika Anda tidak dapat menautkan akun Anda."
      },
      {
        id: 18,
        title: "Saya ingin mengubah akun Google yang ditautkan dengan akun game saya atau menghapus akun game saya untuk memulai kembali. Apa yang harus saya lakukan?",
        desc: "Anda dapat menghubungi tim dukungan untuk membantu Anda. Tetapi hanya melalui kontak umpan balik dalam game karena kami perlu memeriksa otoritas antara Anda dan akun."
      },
      {
        id: 19,
        title: "Saya membeli paket dalam permainan tetapi tidak menerimanya.",
        desc: "Biasanya disebabkan oleh keterlambatan. Jika Anda masih tidak menerima paket untuk waktu yang lama. Silakan hubungi tim dukungan kami dengan nomor transaksi Google Play (GPA.XXX) untuk membantu pengecekan."
      },
      {
        id: 20,
        title: "Saya tidak bisa menonton iklan untuk mengklaim hadiah saya.",
        desc: "Itu bisa disebabkan oleh beberapa alasan. Seperti masalah koneksi atau perangkat Anda mungkin memblokirnya. Dan jika beberapa daerah tidak memiliki penyedia iklan yang cukup maka iklan tidak dapat dimuat. Anda dapat mencoba menggunakan VPN untuk membantu Anda memecahkan masalah pemuatan atau membeli paket hapus iklan untuk mengklaim hadiah tanpa menontonnya."
      },
      {
        id: 21,
        title: "Apakah ada hadiah untuk pelaporan bug?",
        desc: "Tentu saja. Jika bug yang Anda laporkan telah dikonfirmasi. Anda akan mendapatkan hadiah berdasarkan efek bug dan seberapa detail deskripsi Anda diberikan. Jadi melaporkan bug dengan screenshot atau video akan lebih baik."
      }
    ]
  }
}
export default message