import img41 from '../../assets/images/faq/4-1.png'
import img51 from '../../assets/images/faq/5-1.png'
import img52 from '../../assets/images/faq/5-2.png'
import img151 from '../../assets/images/faq/15-1.png'
const message = {
  index: {
    pageName: 'INDEKS',
    gamefeatures: 'CIRI-CIRI PERMAINAN'
  },
  shop: {
    pageName: 'KEDAI',
  },
  faq: {
    pageName: 'Soalan Lazim',
    faqList: [
      {
        id: 1, 
        title: "Tembakan naga secara manual meletihkan saya. Adakah terdapat cara yang lebih mudah untuk bermain?",
        desc: "Ya. Anda boleh memilih untuk menggunakan pertempuran automatik dalam pelbagai jenis mod pertempuran. Ia akan menjadikan naga anda memilih sasaran secara automatik dan berubah apabila sasaran dibunuh. Anda masih boleh memilih sasaran sendiri untuk menentang musuh yang berancaman tinggi dan ia tidak akan mengganggu mod pertempuran automatik. Jangan lupa untuk mengucapkan mantra, mereka masih memerlukan kawalan anda secara manual. Anda boleh membuka fungsi ini selepas menyelesaikan kawasan 2 tahap 4 Windland ."
      },
      {
        id: 2, 
        title: "Apakah fungsi mudah yang boleh dibuka untuk mempercepat permainan saya?",
        desc: "Selain pertempuran automatik boleh dibuka dari ganjaran tahap, anda boleh membuka kebanyakan fungsi lain daripada ganjaran tahap VIP, termasuk cabaran pantas rompakan, percepatan pertempuran dan cabaran pantas Boss Dunia. "
      },
      {
        id: 3,
        title: 'Saya memilih sasaran tetapi naga saya tidak memberi sebarang kerosakan padanya.',
        desc: "Serangan naga sebenarnya mempunyai balistik. Jadi ia akan menghalang serangan naga anda jika terdapat musuh lain (seperti mesin pengepungan) di hadapan sasaran. Untuk menangani masalah ini, lebih baik cuba gunakan lebih banyak serangan AOE."
      },
      {
        id: 4,
        title: "Menyerang butang pembelian menara mempunyai efisien rendah. Bagaimana saya boleh menambah baiknya?",
        desc: "Anda boleh memilih bintang menara yang anda ingin beli. Tekan butang bintang di bahagian bawah kanan halaman edit menara dan anda boleh memilihnya kemudian. Bintang permulaan yang lebih tinggi perlu dibuka dengan memperoleh menara bintang tinggi. Anda boleh menekan untuk menyemak keperluan. （Kedudukan pintu masuk berada pada bulatan merah dalam imej di bawah）",
        img: [
          img41
        ]
      },
      {
        id: 5,
        title: "Saya mempunyai terlalu banyak menara yang tidak berguna. Bagaimana saya boleh berurusan dengan mereka?",
        desc: "Anda boleh mengitar semulanya dengan menggabungkannya untuk menara yang berkualiti tinggi. Tekan butang tanda pengitar semula di bahagian tengah kanan halaman edit menara untuk memasuki halaman gabungan. Anda boleh menyemak kebarangkalian setiap keputusan jika anda memilih tiga menara yang mempunyai bintang yang berbeza. Dan anda boleh menekan butang tanda soal untuk melihat lebih banyak panduan. (Kedudukan pintu masuk berada pada bulatan merah dalam imej di bawah)",
        img: [
          img51,
          img52
        ]
      },
      {
        id: 6,
        title: "Kenapa saya mampu mengkalahkan pemain lain yang lebih lemah daripada saya dalam arena atau perang pulau liga",
        desc: "Keputusan pertempuran bukan sahaja bergantung pada jumlah kuasa, tetapi juga akan dipengaruhi oleh kombinasi antara anda dan pemain lain. Sebagai contoh, sebahagian pemain yang mempunyai laburan tinggi dalam beberapa bahagian pasukan mereka mungkin menjadikan mereka kelihatan lemah tetapi mempunyai kekuatan untuk menentang pemain berkuasa tinggi."
      },
      {
        id: 701, // 补充的一条
        title: "Bagaimanakah saya berurusan dengan pemain curang dalam arena atau bos dunia?",
        desc: 'Anda boleh melaporkannya kepada pasukan sokongan kami. Anda boleh mencari pemain curang di halaman log di arena dan di halaman kedudukan di bos dunia. Tekan "laporan" untuk menghantar mel kepada kami kemudian. Jika kami mengesahkan tingkah laku sebagai penipuan, kami akan memberi amaran kepada pemain curang kali pertama. Jika dia terus menipu, dia akan mendapat 3 hari penyekatan akaun. Dan hukuman akan terus meningkat jika dia tidak bertaubat.'
      },
      {
        id: 7,
        title: "Saya menaiktarafkan istana saya tetapi pengeluaran sumber saya tidak meningkat.",
        desc: "Penaiktarafan istana hanya akan meningkatkan kapasiti penyimpanan setiap sumber. Untuk meningkatkan pengeluaran sumber, anda perlu memainkan mod portal kebuntuan untuk mendapat lebih banyak bangunan yang boleh diletakkan di pulau anda dan ditingkatkan."
      },
      {
        id: 8,
        title: "Pemimpin liga kami tidak log masuk ke dalam permainan selama beberapa hari dan kami tidak boleh melancarkan perang pulau liga.",
        desc: "Buat masa ini, pemimpin liga akan ditendang secara automatik selepas sepuluh hari tidak log masuk. Kami sedang merancang sistem penyingkiran undi untuk memperbaiki masalah ini."
      },
      {
        id: 9,
        title: "Adakah perkembangan saya dalam kem latihan akan hilang apabila saya keluar daripada liga?",
        desc: "Tidak. Tahap kem latihan berkaitan dengan anda, bukan liga dan semua orang mempunyai perkembangan mereka sendiri. Tapi anda akan kehilangan peminatnya sehingga anda menyertai liga seterusnya."
      },
      {
        id: 10,
        title: "Mengapakah hasil yang dibekalkan untuk dituntut dalam pulau lebih tinggi daripada jumlah hasil saya kadangkala?",
        desc: "Jika liga anda telah menakluki pulau baru, hasil tuntutan pembekal akan meningkat serta-merta tetapi bukan untuk jumlah hasil."
      },
      {
        id: 11,
        title: "Jika saya menyertai/keluar dari liga dalam perang pulau liga. Apakah akibatnya?",
        desc: "Apabila anda menyertai/berhenti daripada liga, anda tidak akan dapat menyertai perang pulau liga jika ia sudah bermula dan tiada sebarang ganjaran daripadanya dapat diterima."
      },
      {
        id: 12,
        title: "Bagaimana peluang memenangi perang pulau liga boleh ditingkatkan?",
        desc: "Pemenang perang pulau liga ditentukan oleh markah yang diperoleh liga anda. Jadi kuasa bukan satu elemen kemenangan tetapi usaha. Jika liga anda boleh memberi tumpuan kepada penggunaan Rum（stamina perang pulau liga) dan menakluki pulau yang memberi lebih banyak markah akan meningkatkan peluang kemenangan anda."
      },
      {
        id: 13,
        title: "Bagaimanakah saya boleh menghubungi pasukan sokongan permainan jika saya menghadapi masalah? Berapa lama saya akan menerima balasan?",
        desc: "<p>Anda boleh menggunakan maklum balas dalam permainan untuk menghubungi kami. Butang berada dalam menu tetapan permainan. Kami akan membalas anda dalam masa 24 jam, bergantung pada zon waktu dan kesan lain. Anda juga boleh menghubungi kami melalui cara di bawah.</p><p>Facebook: <a href='https://www.facebook.com/NorthTowerGame'>https://www.facebook.com/NorthTowerGame</a></p><p>Twitter: <a href='https://twitter.com/NorthTowerGame'>https://twitter.com/NorthTowerGame</a></p>"
      },
      {
        id: 14,
        title: "Saya membeli pek yang salah secara tidak sengaja dalam permainan. Bagaimanakah saya boleh mendapatkan bayaran balik?",
        desc: "Anda boleh meminta bayaran balik daripada pasukan sokongan Google Play dengan segera. Biasanya tidak akan menyebabkan permintaan bayaran balik anda gagal dalam masa dua jam dan tidak melebihi 48 jam selepas pembelian."
      },
      {
        id: 15,
        title: "Saya memasuki permainan dengan bahasa yang salah. Di mana saya boleh mengubahnya?",
        desc: "Anda boleh menukar bahasa permainan anda dalam menu tetapan. (Kedudukan pintu masuk berada pada bulatan merah dalam imej di bawah.)",
        img: [img151]
      },
      {
        id: 16,
        title: "Permainan saya berjalan dengan ketinggalan atau gangguan.",
        desc: "Permainan saya berjalan dengan ketinggalan atau gangguan."
      },
      {
        id: 17,
        title: "Bagaimanakah saya harus memautkan akaun saya kepada akaun Google saya dan mengapa saya perlu melakukannya?",
        desc: "Anda boleh memasuki menu tetapan selepas anda menyelesaikan beberapa tutorial. Ini amat penting kerana anda mungkin kehilangan data akaun anda semasa anda menukar peranti atau menyahpasang permainan,. Sila pautkan akaun anda untuk melindungi data permainan anda atau sekurang-kurangnya ingat ID anda yang ditunjukkan pada menu tetapan jika anda tidak dapat memautkan akaun anda."
      },
      {
        id: 18,
        title: "Saya ingin menukar akaun Google yang dipautkan kepada akaun permainan saya atau memadamkan akaun permainan saya untuk memulakan semula. Apa patut saya buat?",
        desc: "Anda boleh menghubungi pasukan sokongan untuk mendapat bantuan. Anda hanya boleh menghubungi kami melalui maklum balas dalam permainan kerana kami perlu menyemak identiti antara anda dan akaun."
      },
      {
        id: 19,
        title: "Saya membeli pek dalam permainan tetapi tidak menerimanya.",
        desc: "Ia biasanya disebabkan oleh kelewatan. Jika anda masih belum menerima pek dalam masa yang lama. Sila hubungi pasukan sokongan kami dengan nombor bil Google Play (GPA.XXX) untuk membantu melakukan semakan."
      },
      {
        id: 20,
        title: "Saya tidak mampu menonton iklan untuk menuntut ganjaran saya.",
        desc: "Ia boleh disebabkan oleh beberapa sebab. Seperti masalah sambungan, peranti andamungkin menyekatnya dan sesetengah kawasan tidak mempunyai penyedia iklan yang mencukupi mungkin menyebabkan iklan tidak dapat dimuatkan. Anda boleh cuba menggunakan VPN untuk membantu anda menyelesaikan masalah pemuatan atau membeli pek pengalih keluar iklan untuk menuntut ganjaran anda tanpa menontonnya."
      },
      {
        id: 21,
        title: "Adakah terdapat sebarang ganjaran untuk melaporkan pepijat?",
        desc: "Ya. Jika pepijat yang anda laporkan telah disahkan, anda akan mendapat ganjaran berdasarkan kesan pepijat dan seberapa terperinci penerangan anda diberikan. Jadi lebih baik melaporkan pepijat dengan tangkapan skrin atau video."
      }
    ]
  }
}
export default message