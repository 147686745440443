import { Locale } from 'vant'
import en from './lang/en'
import zh from './lang/zh'
import zht from './lang/zht'
import de from './lang/de'
import pt from './lang/pt'
import fr from './lang/fr'
import es from './lang/es'
import ja from './lang/ja'
import ko from './lang/ko'
import it from './lang/it'
import ru from './lang/ru'
import th from './lang/th'
import id from './lang/id'
import my from './lang/my'
import vi from './lang/vi'
export function Local(a) {
  switch(a){
    case 'en':
      Locale.use('en', en)
      break;
    case 'zh':
      Locale.use('zh', zh)
      break;
    case 'zht':
        Locale.use('zht', zht)
        break;
    case 'de':
      Locale.use('de', de)
      break;
      case 'pt':
        Locale.use('pt', pt)
        break;
    case 'fr':
      Locale.use('fr', fr)
      break;
      case 'es':
      Locale.use('es', es)
      break;
    case 'ja':
      Locale.use('ja', ja)
      break;
    case 'ko':
      Locale.use('ko', ko)
      break;
    case 'it':
      Locale.use('it', it)
      break;
    case 'ru':
      Locale.use('ru', ru)
      break;
    case 'th':
      Locale.use('th', th)
      break;
    case 'id':
      Locale.use('id', id)
      break;
    case 'my':
        Locale.use('my', my)
        break;
    case 'vi':
        Locale.use('vi', vi)
        break;
    default:
      Locale.use('en-US', en)
  }
}