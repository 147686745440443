import img41 from '../../assets/images/faq/4-1.png'
import img51 from '../../assets/images/faq/5-1.png'
import img52 from '../../assets/images/faq/5-2.png'
import img151 from '../../assets/images/faq/15-1.png'
const message = {
  index: {
    pageName: '主頁',
    gamefeatures: '遊戲特色'
  },
  shop: {
    pageName: '商城',
  },
  faq: {
    pageName: '常問問題',
    faqList: [
      {
        id: 1, 
        title: "手動瞄準龍玩著很累，有什麼簡單些的玩法嗎？",
        desc: "是的，你可以在多種戰鬥模式中選擇使用自動戰鬥。它將使你的龍自動選擇目標並在目標被消滅時自動切換。你仍然可以自己選擇目標以對付高威脅敵人，而且這不會中斷自動戰鬥。不要忘記施放法術，它們仍然需要你手動控制。你可以在完成風之國區域2第4關後解鎖這個功能。"
      },
      {
        id: 2, 
        title: "有什麼方便的功能可以加快我的遊戲進程嗎？",
        desc: "除了自動戰鬥可以通過關卡獎勵獲得外。你還可以從VIP等級獎勵中解鎖大部分其他便利功能，包括劫掠掃蕩、戰鬥加速、和世界boss掃蕩。"
      },
      {
        id: 3,
        title: '我選中了目標但我的龍沒有對其造成任何傷害',
        desc: "龍的攻擊其實是有彈道的，因此如果目標前方有其他敵人（比如攻城車）會導致龍的攻擊被擋住。通過使用更多AOE攻擊來處理這種情況會更有效。"
      },
      {
        id: 4,
        title: "一直點買塔按鈕效率太低了，有什麼辦法改進嗎？",
        desc: "你可以選擇想要購買的塔的初始星級。在編輯介面點擊星形按鈕即可選擇。更高的初始星級需要你獲得高星級塔來解鎖，你可以點擊並查看解鎖條件。（入口位置顯示在下圖紅圈處）",
        img: [
          img41
        ]
      },
      {
        id: 5,
        title: "我有太多沒用的塔了，該怎麼處理他們？",
        desc: "你可以將他們合成成更高品質的塔。點擊塔編輯介面中部靠右的回收按鈕進入合成介面。如果你選擇了三個不全是相同星級的塔時可以看到每種結果的概率。你還可以點擊問號按鈕看到更多說明。（入口位置顯示在下圖紅圈處）",
        img: [
          img51,
          img52
        ]
      },
      {
        id: 6,
        title: "為什麼我會在競技場或公會戰中被比我弱的玩家打敗？",
        desc: "戰鬥的結果並不只是取決於戰力值，也會敵我的防禦塔組合的相性影響。比如有些玩家會集中強化隊伍中的部分戰力，這使得他們看起來很弱但實戰中卻有反而擊敗高戰力玩家的實力。"
      },
      {
        id: 701, // 补充的一条
        title: "我該怎麼應對競技場或世界boss中遇到的作弊玩家？ ",
        desc: "你可以將他們舉報給我們。你能在競技場的交戰日誌中和世界boss的排行榜中找到作弊玩家，然後點擊“舉報”即可向我們發送郵件。如果我們確認存在作弊行為，首先我們會警告作弊玩家，若未停止作弊則會封號3天。如果仍沒有改正懲罰將持續加重。 "
      },
      {
        id: 7,
        title: "我升級了我的宮殿但資源產量沒有增加 ",
        desc: "宮殿升級只會增加每種資源的儲存上限。想增加資源產量需要你遊玩絕境之門，從中獲取更多建築放置在島嶼上並升級它們。 "
      },
      {
        id: 8,
        title: "我們的公會長已經好多天沒登入了，沒法開啟島嶼爭奪戰怎麼辦？",
        desc: "目前若十天沒有登入的話公會長會被自動踢出。我們正計畫增添一個投票踢出系統來改善這個問題。"
      },
      {
        id: 9,
        title: "我在公會訓練營的進度會因為我退出公會而丟失嗎？",
        desc: "不會。訓練營的進度是與你而不是與公會綁定的，每個人都有屬於他自己的進度。但你會失去訓練營的增益效果，直到你加入下一個公會。"
      },
      {
        id: 10,
        title: "為什麼即將獲取的島嶼補給有時比我總計獲得補給的量要多？",
        desc: "如果你的公會佔領了新島嶼，即將獲取的補給會立即增加，但總計獲取已存在的量不會。 "
      },
      {
        id: 11,
        title: "如果我在島嶼爭奪戰期間退出公會有什麼代價嗎？ ",
        desc: "當你在島嶼爭奪戰已經開始後退出或加入新公會時，你將不能加入島嶼爭奪戰也不能從中獲取任何獎勵 "
      },
      {
        id: 12,
        title: "如何增加島嶼爭奪戰中的勝率？",
        desc: "島嶼爭奪戰的勝者取決於你公會獲得的分數。所以戰力並不是決定勝利的唯一因素，努力也是。如果你的公會能集中使用朗姆酒（島嶼爭奪戰體力）並佔領那些提供更多分數的島嶼將增加你的勝率。 "
      },
      {
        id: 13,
        title: "如果我遇到問題的話我該如何聯繫遊戲的支援團隊？我多久可以收到回復？",
        desc: "<p>你可以使用遊戲內回饋來給我們發送郵件。回饋按鈕在遊戲設置功能表內。我們會在24小時內回復你，取決於時區或其他因素影響。你也可以通過以下方式聯繫我們。</p><p>Facebook: <a href='https://www.facebook.com/NorthTowerGame'>https://www.facebook.com/NorthTowerGame</a></p><p>Twitter: <a href='https://twitter.com/NorthTowerGame'>https://twitter.com/NorthTowerGame</a></p>"
      },
      {
        id: 14,
        title: "我意外錯買了遊戲禮包，該如何獲得退款？",
        desc: " 你可以立即聯繫穀歌Play的支援團隊申請退款。在購買後兩小時內或至少不超過48小時通常不會導致你的退款申請失敗。"
      },
      {
        id: 15,
        title: "我進遊戲時顯示的是錯誤的語言，應該在哪裡改？",
        desc: "你可以在設置功能表中修改遊戲語言（入口位置顯示在下圖紅圈處）",
        img: [img151]
      },
      {
        id: 16,
        title: "我的遊戲運行起來會卡頓或故障",
        desc: "此情況可能是由於網路連接問題或著你的手機負荷太高。請檢查或更換你的網路環境。你也可以在設置功能表中降低解析度和特效。"
      },
      {
        id: 17,
        title: "我該怎麼將我的帳號與穀歌帳號連結？有什麼必要嗎？",
        desc: "你可以在完成少量教學後進入設置功能表並找到連結按鈕。這很重要，因為如果你切換了設備或卸載了遊戲，你可能失去你的帳號資料。請連結你的帳號以保護帳號資料。如果你無法連結你的帳號，請至少記住顯示在設置功能表的你的領主ID。"
      },
      {
        id: 18,
        title: "我想更換與我帳號連結的穀歌帳號或是刪除帳號以重新開始，我該怎麼做？",
        desc: "你可以聯繫我們的支援團隊來協助你。但必須通過遊戲內回饋進行聯繫因為我們需要確認你擁有帳號的所有權。"
      },
      {
        id: 19,
        title: "我在遊戲內購買了禮包但沒收到",
        desc: "通常這是由於延遲導致的。如果你長時間沒有收到禮包的話，請聯繫我們的支援團隊並發送穀歌訂單號（GPA.XXX）來協助我們檢查問題。"
      },
      {
        id: 20,
        title: "我看不了廣告，這導致我沒法獲取相關獎勵",
        desc: "這可能是由於多種原因造成的，像是連接問題或者你的設備遮罩了廣告。如果有些地區沒有足夠的廣告供應商可能導致廣告無法載入。你可以嘗試使用VPN幫助你解決這個問題或是購買去除廣告禮包來跳過看廣告直接獲得獎勵。"
      },
      {
        id: 21,
        title: "回饋BUG有獎勵拿嗎？ ",
        desc: "當然。如果你回饋的BUG被確認存在，你將得到一份獎勵，數量基於此BUG的影響程度和你回饋時的細節多寡。所以回饋BUG時附帶截圖或錄屏會更好。"
      }
    ]
  }
}
export default message