import img41 from '../../assets/images/faq/4-1.png'
import img51 from '../../assets/images/faq/5-1.png'
import img52 from '../../assets/images/faq/5-2.png'
import img151 from '../../assets/images/faq/15-1.png'
const message = {
  index: {
    pageName: '목록',
    gamefeatures: '게임 특징'
  },
  shop: {
    pageName: '상점',
  },
  faq: {
    pageName: '자주 묻는 질문',
    faqList: [
      {
        id: 1, 
        title: "드래곤을 수동 조준하기가 힘들어요. 더 간단한 방법이 없을까요?",
        desc: "있습니다. 자동 전투 모드를 사용해 보세요. 자동 전투 모드를 활성화하면 드래곤이 자동으로 타깃을 설정하여 처치하고 새 타깃을 자동 설정합니다. 자동 모드에서도 수동 조작으로 위협적인 적을 선택해 상대할 수 있으며 자동 전투 모드가 종료되지 않습니다. 마법 시전도 잊지 마세요. 자동 전투 모드에서는 수동 조작으로 마법도 시전할 수 있으니까요. 자동 전투 모드는 바람나라 2-4를 클리어하면 잠금 해제할 수 있습니다."
      },
      {
        id: 2, 
        title: "게임 진행 속도를 높이기 위해 잠금 해제할 만한 편리 기능에는 어떤 것이 있나요?",
        desc: "자동 전투 모드 잠금 해제 외에도 VIP 레벨 보상으로 약탈 모드 빠른 도전, 전투 가속, 월드BOSS 빠른 도전 등 다양한 기능을 잠금 해제할 수 있습니다."
      },
      {
        id: 3,
        title: '선택한 타깃에 드래곤의 데미지가 적용되지 않아요.',
        desc: "드래곤의 공격은 사실 탄도입니다. 타깃 앞에 있는 다른 적(예를 들어, 공성차)이 있다면 드래곤의 공격이 차단될 수 있습니다. 광역 공격을 더 많이 사용하면 이 문제를 해결할 수 있습니다."
      },
      {
        id: 4,
        title: "타워 구매 효율이 떨어져요. 어떻게 높일 수 있나요?",
        desc: "타워 구매 시 성급을 선택할 수 있습니다. 타워 편집 인터페이스 오른쪽 아래에 있는 별 아이콘을 탭하면 선택할 수 있습니다. 더 높은 성급으로 설정하고 싶다면 더 높은 성급의 타워를 획득해야 잠금 해제할 수 있습니다. 탭하여 잠금 해제 요건을 확인하세요. (위치는 아래 이미지의 붉은색 원을 참고하세요)",
        img: [
          img41
        ]
      },
      {
        id: 5,
        title: "쓸모없는 타워가 너무 많아요. 어떻게 처리하면 좋을까요?",
        desc: "쓸모없는 타워를 회수하여 재편성하면 더 좋은 타워를 획득할 수 있습니다. 편집 인터페이스 중간 오른쪽에 위치한 ‘회수’ 아이콘을 탭하여 타워 재편성 인터페이스로 이동하세요. 여러 성급의 타워 3개를 선택하면 재편성 예상 확률을 확인할 수 있습니다. 그리고 물음표 아이콘을 탭하면 더 상세한 설명을 확인할 수 있습니다. (위치는 아래 이미지의 붉은색 원을 참고하세요)",
        img: [
          img51,
          img52
        ]
      },
      {
        id: 6,
        title: "전투력이 제가 더 높은데 왜 아레나나 길드 섬 전쟁에서 지는 거죠?",
        desc: "전투 결과는 전투력 숫자로만 결정되지 않습니다. 영주님과 다른 영주님 간의 상성도 영향을 미칩니다. 예를 들어, 길드로부터 여러 부분 받는 것이 많을 경우, 보기에는 약해 보이지만 자신보다 더 높은 전투력의 플레이어도 상대할 수 있을 만큼 강한 실제 전투력을 갖게 되는 것입니다."
      },
      {
        id: 701, // 补充的一条
        title: "아레나 혹은 월드BOSS에 어뷰징 유저가 있어요. 어떻게 해야 하나요?",
        desc: "고객지원팀에게 신고해해주세요. 아레나 기록 인터페이스나 월드BOSS 랭킹 인터페이스에서 어뷰징 유저를 찾아 ‘신고’를 탭하여 우편을 보내주세요. 해당 어뷰징 행위가 사실로 확인되면 경고 조치를 내립니다. 만약에 해당 유저가 어뷰징 행위를 지속할 경우, 계정이 3일 정지됩니다. 그럼에도 해당 플레이어가 어뷰징 행위를 지속할 경우, 해당 유저가 받는 처벌 강도는 계속해서 증가합니다. "
      },
      {
        id: 7,
        title: "황궁을 업그레이드했는데 자원 생산량이 증가하지 않아요.",
        desc: "황궁을 업그레이드하면 각종 자원의 저장량만 증가합니다. 만약 자원 생산량을 증가시키고 싶다면 절망의 문에서 더 많은 건축을 획득해 아일랜드에 배치하고 업그레이드를 해야 합니다."
      },
      {
        id: 8,
        title: "길드장이 며칠간 게임에 접속하지 않아서 길드섬 쟁탈에 참여할 수 없어요.",
        desc: "이제 길드장이 10일간 게임에 접속하지 않을 경우, 길드장이 길드에서 자동 퇴출됩니다. 현재 문제 해결을 위해 투표 퇴출 시스템을 개발 중에 있습니다."
      },
      {
        id: 9,
        title: "길드를 나가면 훈련소 진행도가 사라지나요?",
        desc: "그렇지 않습니다. 훈련소 레벨은 길드 전체가 아닌 개인별로 적용되며 영주님마다 진행도가 다릅니다. 하지만 훈련소 버프는 새로운 길드에 가입해야 다시 적용됩니다."
      },
      {
        id: 10,
        title: "왜 섬에서 획득할 수 있는 자원이 총 자원 생산량보다 많을 때가 있는 거죠?",
        desc: "영주님의 길드가 새로운 섬을 점령할 경우, 획득할 수 있는 자원 생산량이 즉시 증가합니다. 하지만 자원 총 생산량에는 변동이 없습니다."
      },
      {
        id: 11,
        title: "길드섬 쟁탈 중에 길드에 가입/탈퇴하면 어떻게 되나요?",
        desc: "길드섬 쟁탈 시작 후, 해당 기간 동안 길드를 가입하거나 탈퇴하면 길드섬 쟁탈에 참여할 수 없으며 어떠한 보상도 획득할 수 없습니다."
      },
      {
        id: 12,
        title: "길드섬 쟁탈 승률을 높이려면 어떻게 해야 되나요?",
        desc: "길드섬 쟁탈의 승패는 영주님의 길드가 얼마나 많은 점수를 획득하느냐에 달려있습니다. 그러므로 실력은 승패를 결정짓는 유일한 요소가 아니며 노력 또한 중요합니다. 영주님의 길드가 럼주(길드섬 쟁탈 체력)를 최대한으로 사용하고 더 높은 점수의 섬을 점령한다면 영주님의 길드가 승리할 확률이 증가할 것입니다."
      },
      {
        id: 13,
        title: "문제가 생겼을 때 어떻게 해야 고객지원팀에 연락할 수 있나요? 답변 받는 데 시간은 얼마나 걸리나요?",
        desc: "<p>게임 내 피드백으로 연락 주세요. 해당 기능은 ‘게임 설정’ 메뉴에 있습니다. 24시간 내에 답변해 드리며 구체적인 답변 시간은 표준 시간대 및 기타 요인으로 달라질 수 있습니다. 다음 루트로도 고객지원팀과 연락할 수 있습니다.</p><p>Facebook: <a href='https://www.facebook.com/NorthTowerGame'>https://www.facebook.com/NorthTowerGame</a></p><p>Twitter: <a href='https://twitter.com/NorthTowerGame'>https://twitter.com/NorthTowerGame</a></p>"
      },
      {
        id: 14,
        title: "패키지를 잘못 구매했어요. 환불받으려면 어떻게 해야 하나요?",
        desc: "Google play 고객지원팀에 환불을 즉시 요청하세요. 일반적으로 구매 후 2시간 내 혹은 최대 48시간 이내에 환불을 요청하면 받을 수 있습니다."
      },
      {
        id: 15,
        title: "언어를 잘못 선택했어요. 어떻게 변경할 수 있나요?",
        desc: "‘설정’ 메뉴에서 언어를 변경할 수 있습니다. (위치는 아래 이미지의 붉은색 원을 참고하세요)",
        img: [img151]
      },
      {
        id: 16,
        title: "게임 중에 렉이나 오류가 발생해요",
        desc: "연결 문제 혹은 기기 과부하 문제일 수 있습니다. 연결 상태를 확인하고 변경해 보세요. 또한, 설정 메뉴에서 특수효과와 해상도를 낮출 수 있습니다."
      },
      {
        id: 17,
        title: "게임 계정과 Google 계정 연동은 어떻게 하나요? 연동하면 뭐가 좋나요?",
        desc: "튜토리얼을 끝낸 후 설정 메뉴에서 계정 연동을 할 수 있습니다. 계정 연동은 매우 중요합니다. 만약에 계정을 연동하지 않고 기기를 변경하거나 게임을 제거하면 게임 계정 데이터가 사라집니다. 그러므로 영주님의 게임 데이터를 보호하기 위해서는 계정 연동을 하시기 바랍니다. 계정 연동이 안되는 경우에는 영주 ID를 기억해 두세요. 영주 ID는 설정 메뉴에서 확인할 수 있습니다."
      },
      {
        id: 18,
        title: "게임 계정과 연동된 계정을 다른 Google 계정으로 변경하거나 게임 계정을 삭제하고 다시 시작하고 싶은데 어떻게 해야 되나요?",
        desc: "고객지원팀에 연락 주시면 도와드리겠습니다. 하지만 권한 문제 때문에 게임 내 피드백을 통해서 고객지원팀에 연락해 주셔야 영주님과 영주님의 계정을 확인할 수 있습니다."
      },
      {
        id: 19,
        title: "게임에서 패키지를 구입했는데 받지 못했어요.",
        desc: "지연이 있을 경우에 주로 발생합니다. 만약 오래 기다려도 패키지를 받지 못한 경우, 고객지원팀에게 연락 주세요. 영주님의 Google Play 계정 아이디 (GPA.XXX)를 보내주시면 확인 도와드리겠습니다."
      },
      {
        id: 20,
        title: "광고로 보상을 획득할 수가 없어요.",
        desc: "여러 이유가 있을 수 있습니다. 인터넷 연결 문제이거나 기기 차단으로 발생할 수도 있습니다. 만약에 해당 국가에 광고 공급자가 충분하지 않은 경우에도 광고를 로딩할 수 없을 수 있습니다. VPN을 사용하여 광고 로딩 문제를 해결하거나 광고 제거 패키지를 구매하면 광고를 보지 않더라도 보상을 획득할 수 있습니다."
      },
      {
        id: 21,
        title: "버그 신고하면 보상도 주나요?",
        desc: "물론입니다. 버그 신고가 사실로 확인되면 버그의 영향력과 설명이 얼마나 상세한지에 따라 보상을 드립니다. 그러므로 버그 신고 시 스크린샷 혹은 영상을 첨부해 주신다면 더욱 좋습니다."
      }
    ]
  }
}
export default message