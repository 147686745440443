import img41 from '../../assets/images/faq/4-1.png'
import img51 from '../../assets/images/faq/5-1.png'
import img52 from '../../assets/images/faq/5-2.png'
import img151 from '../../assets/images/faq/15-1.png'
const message = {
  index: {
    pageName: '目次',
    gamefeatures: 'ゲームの特徴'
  },
  shop: {
    pageName: 'ショップ',
  },
  faq: {
    pageName: 'よくある質問',
    faqList: [
      {
        id: 1, 
        title: "ドラゴンの照準調整が手動なので、結構疲れます。もっと簡単にプレイする方法はありませんか？",
        desc: "各種戦闘モードで、オートバトル機能を使ってみてください。ドラゴンは自動的にターゲットを攻撃し、敵を撃破した後に新しいターゲットを見つけます。オートバトル機能を中断せずに、脅威の高い敵を優先的に攻撃したい時は、手動で目標を指定することもできます。ただし、スペルは通常通り、手動で発動する必要があります。オートバトル機能は、しなければなりませんので、この機能は、風の国エリア2のステージ4をクリアするとアンロックされます。"
      },
      {
        id: 2, 
        title: "ゲームをもっと早く進める便利な機能はありません？",
        desc: "レベルをクリアすることでアンロックできるオートバトル機能のほか、VIPレベルをあげることで、クイックチャレンジ、高速バトルやワールドボスチャレンジなど、さらにたくさんの機能を解放できます。 "
      },
      {
        id: 3,
        title: 'ターゲットを指定したのに、ドラゴンはターゲットにダメージを与えようとしません。',
        desc: "ドラゴンの攻撃には弾道があるので、ターゲットの前に他の敵（例えば、攻城機など）がいると、ドラゴンの攻撃はブロックされてしまいます。このような場面では、範囲攻撃を活用してみましょう。"
      },
      {
        id: 4,
        title: "タワー購入ボタン連打するだけでは効率があまりにも低すぎます。他によい方法はありませんか？",
        desc: "タワー編集画面の右下にある星ボタンを押して、購入したいタワーの星数を指定できます。高い星ランクのタワーを入手すれば、初期から利用できるタワーの星ランクが上がります。タワーをタップして、アンロック条件を確認できます。(下の画像の赤い線で囲まれているボタンです）",
        img: [
          img41
        ]
      },
      {
        id: 5,
        title: "役に立たないタワーが多すぎます。処理する方法はありませんか？",
        desc: "低い品質のタワーをより高品質なタワーにマージできます。タワー編集画面右中央にあるリサイクルマークのボタンをタップして、星ランクの違う3つのタワーを選べば、それぞれのマージ結果がわかります。(下の画像の赤い線で囲まれているボタンです）",
        img: [
          img51,
          img52
        ]
      },
      {
        id: 6,
        title: "闘技場やリーグ戦の島で、自分より弱いプレイヤーに負けてしまいました。",
        desc: "戦力のほかにも、戦闘結果を左右する要素があります。相手との相性のこともあります。例えば、一部のプレイヤーは、チームの平均戦力が弱そうに見えても、強いプレーヤーをカウンターできる一部のユニットを極めて高いレベルまで育成しています。"
      },
      {
        id: 701, // 补充的一条
        title: "カスタマーサービスへご報告ください。チート行為を行ったプレイヤーの情報は、闘技場のログ画面、ワールドボスのランキング画面で確認できます。「通報」をタップして、私たちにメールで報告してください。チート行為が確認されれば、該当プレイヤーに警告を行います。さらにチート行為を行った場合、3日間アカウント停止処分を下します。行為を是正するまで、罰則を課し続けます。"
      },
      {
        id: 7,
        title: "8.宮殿をアップグレードしたのに、資源の生産量が増えません。",
        desc: "宮殿をアップグレードしても、各資源は貯蔵容量が増えるだけです。資源の生産量を増やすには、袋小路モードをプレイして、自分の島に置ける建物を入手して、アップグレードしなければなりません。"
      },
      {
        id: 8,
        title: "リーグリーダーがここ数日前間ずっとオフライン状態のため、リーグの島バトルを開始できません。",
        desc: "今のところ、リーグリーダーが10日間オフラインし続けると、自動的に追放されます。今後は、追放投票を導入する予定です。"
      },
      {
        id: 9,
        title: "リーグから脱退すると、トレーニングキャンプの進度はなくなりますか？",
        desc: "いいえ。トレーニングキャンプのレベルは、プレイヤー自身とリンクしています。リーグとは無関係です。プレイヤーはそれぞれ独自の進捗を持っています。ただし、リーグに再度加入するまで、各種バフは利用できなくなります。 "
      },
      {
        id: 10,
        title: "島の資源補給量が時々、自身の合計量より多くなるのはなぜですか？",
        desc: "所属のリーグが新しい島を征服した場合、受け取れる補給が増えますが、ご自身の合計量は変化しません。"
      },
      {
        id: 11,
        title: "リーグの島バトル開催中にリーグに参加、または、脱退した場合、何かバッシングはありますか？",
        desc: "リーグの島バトル開催中にリーグに参加、または、脱退した場合、何かバッシングはありますか？"
      },
      {
        id: 12,
        title: "リーグの戦バトルで勝率を上げる方法はありませんか？",
        desc: "リーグの戦バトルの勝敗は、自身が獲得したスコアと所属のリーグが獲得したスコアによって決まります。戦力よりも、努力の方が勝敗に与える影響が大きいです。リーグがラム（リーグの島バトルにおけるスタミナ）の使い方を工夫して、より多くの島を攻略して、もっと得点を稼げば、勝率も上がりますよ。"
      },
      {
        id: 13,
        title: "問題が発生した場合、サポートチームと連絡を取る方法は？回答が得られるまでどのくらい時間かかりますか？",
        desc: "<p>ゲーム内でメールを送信してサポートチートと連絡できます。フィードバックボタンはゲーム設定メニューの中にあります。通常の場合、24時間以内に返信いたします。また、以下の方法でもお問い合わせ頂けます。</p><p>Facebook: <a href='https://www.facebook.com/NorthTowerGame'>https://www.facebook.com/NorthTowerGame</a></p><p>Twitter: <a href='https://twitter.com/NorthTowerGame'>https://twitter.com/NorthTowerGame</a></p>"
      },
      {
        id: 14,
        title: "間違ったパックを買ってしまいましたた。返金できますか？",
        desc: "すぐに Google Play カスタマーサービスに返金を申請してください。通常の場合、2時間以内、あるいは、購入後48時間以内の返金申請は問題なく受理されます。"
      },
      {
        id: 15,
        title: "ゲームの言語設定を間違えてしまいました。どうすれば変更できますか？",
        desc: "ゲームの言語は設定メニューで変更できます。(下の画像の赤い線で囲まれているボタンです）",
        img: [img151]
      },
      {
        id: 16,
        title: "ゲームプレイ中、ラグや不具合が発生しています。",
        desc: "より安定なネットワークに接続するか、ゲームの画質設定をより低く設定してみてください。"
      },
      {
        id: 17,
        title: "Googleアカウントとの連携は必須ですか？",
        desc: "チュートリアルを終えたら、設定メニューを開いて、アカウントを連携しましょう。アカウントを連携しないと、ゲームをアンインストールしたり、デバイスを変更したりすると、アカウントデータが失われる可能性があります。ゲームデータ保護のため、アカウントを連携することをおすすめします。アカウントを連携できない場合は、設定メニューに表示されるロードIDを覚えておいてください。"
      },
      {
        id: 18,
        title: "ゲームアカウントと連動しているGoogleアカウントを変更したい、あるいは、ゲームアカウントを削除してやり直したいのですが、どうすればいいですか？",
        desc: "カスタマーサービスにお問い合わせください。ただし、お客様とアカウントの権限確認が必要のため、必ずゲーム内でお問い合わせください。"
      },
      {
        id: 19,
        title: "ゲームでパックを購入したが、まだ届いていません。",
        desc: "アイテムが反映されるまで、時間がかかる場合があります。長い時間が経過しても、パックが届かなかった場合は、Google Playの請求書番号（GPA.XXX）を添えて、カスタマーサービスまでご連絡ください。"
      },
      {
        id: 20,
        title: "リワード広告を見ることができません。",
        desc: "接続の問題やお使いの端末のアクセス権限など、このような問題を引き起こすいくつかの原因が考えられます。また、一部の地域で、十分な広告がない場合もあります。VPNを利用するか、広告免除パックを購入して広告を見ずに報酬を受け取るなどの方法を試してみてください。"
      },
      {
        id: 21,
        title: "バグを報告すると何かリワードがありますか？",
        desc: "はい、あります。報告したバグが確認された場合、バグの深刻さと説明の詳細さに応じて報酬が支給されます。スクリーンショットや動画でバグを記録すると、フィードバックがより効果的になります。"
      }
    ]
  }
}
export default message