import img41 from '../../assets/images/faq/4-1.png'
import img51 from '../../assets/images/faq/5-1.png'
import img52 from '../../assets/images/faq/5-2.png'
import img151 from '../../assets/images/faq/15-1.png'
const message = {
  index: {
    pageName: '主页',
    gamefeatures: '游戏特色'
  },
  shop: {
    pageName: '商城',
  },
  faq: {
    pageName: '常问问题',
    faqList: [
      {
        id: 1, 
        title: "手动瞄准龙玩着很累，有什么简单些的玩法吗？",
        desc: "是的，你可以在多种战斗模式中选择使用自动战斗。它将使你的龙自动选择目标并在目标被消灭时自动切换。你仍然可以自己选择目标以对付高威胁敌人，而且这不会中断自动战斗。不要忘记施放法术，它们仍然需要你手动控制。你可以在完成风之国区域2第4关后解锁这个功能"
      },
      {
        id: 2, 
        title: "有什么方便的功能可以加快我的游戏进程吗？",
        desc: "除了自动战斗可以通过关卡奖励获得外。你还可以从VIP等级奖励中解锁大部分其他便利功能，包括劫掠扫荡、战斗加速、和世界boss扫荡。"
      },
      {
        id: 3,
        title: '我选中了目标但我的龙没有对其造成任何伤害',
        desc: "龙的攻击其实是有弹道的，因此如果目标前方有其他敌人（比如攻城车）会导致龙的攻击被挡住。通过使用更多AOE攻击来处理这种情况会更有效。"
      },
      {
        id: 4,
        title: "一直点买塔按钮效率太低了，有什么办法改进吗？",
        desc: "你可以选择想要购买的塔的初始星级。在编辑界面点击星形按钮即可选择。更高的初始星级需要你获得高星级塔来解锁，你可以点击并查看解锁条件。（入口位置显示在下图红圈处）",
        img: [
          img41
        ]
      },
      {
        id: 5,
        title: "我有太多没用的塔了，该怎么处理他们",
        desc: "你可以将他们合成成更高质量的塔。点击塔编辑界面中部靠右的回收按钮进入合成界面。如果你选择了三个不全是相同星级的塔时可以看到每种结果的概率。你还可以点击问号按钮看到更多说明。（入口位置显示在下图红圈处）",
        img: [
          img51,
          img52
        ]
      },
      {
        id: 6,
        title: "为什么我会在竞技场或公会战中被比我弱的玩家打败？",
        desc: "战斗的结果并不只是取决于战力值，也会敌我的防御塔组合的相性影响。比如有些玩家会集中强化队伍中的部分战力，这使得他们看起来很弱但实战中却有反而击败高战力玩家的实力。"
      },
      {
        id: 701, // 补充的一条
        title: "我该怎么应对竞技场或世界boss中遇到的作弊玩家？",
        desc: "你可以将他们举报给我们。你能在竞技场的交战日志中和世界boss的排行榜中找到作弊玩家，然后点击“举报”即可向我们发送邮件。如果我们确认存在作弊行为，首先我们会警告作弊玩家，若未停止作弊则会封号3天。如果仍没有改正惩罚将持续加重。"
      },
      {
        id: 7,
        title: "我升级了我的宫殿但资源产量没有增加",
        desc: "宫殿升级只会增加每种资源的储存上限。想增加资源产量需要你游玩绝境之门，从中获取更多建筑放置在岛屿上并升级它们。"
      },
      {
        id: 8,
        title: "我们的公会长已经好多天没登入了，没法开启岛屿争夺战怎么办？.",
        desc: "目前若十天没有登入的话公会长会被自动踢出。我们正计划增添一个投票踢出系统来改善这个问题。"
      },
      {
        id: 9,
        title: "我在公会训练营的进度会因为我退出公会而丢失吗？",
        desc: "不会。训练营的进度是与你而不是与公会绑定的，每个人都有属于他自己的进度。但你会失去训练营的增益效果，直到你加入下一个公会。"
      },
      {
        id: 10,
        title: "为什么即将获取的岛屿补给有时比我总计获得补给的量要多？",
        desc: "如果你的公会占领了新岛屿，即将获取的补给会立即增加，但总计获取已存在的量不会。"
      },
      {
        id: 11,
        title: "如果我在岛屿争夺战期间退出公会有什么代价吗？",
        desc: "当你在岛屿争夺战已经开始后退出或加入新公会时，你将不能加入岛屿争夺战也不能从中获取任何奖励"
      },
      {
        id: 12,
        title: "如何增加岛屿争夺战中的胜率？",
        desc: "岛屿争夺战的胜者取决于你公会获得的分数。所以战力并不是决定胜利的唯一因素，努力也是。如果你的公会能集中使用朗姆酒（岛屿争夺战体力）并占领那些提供更多分数的岛屿将增加你的胜率。"
      },
      {
        id: 13,
        title: "如果我遇到问题的话我该如何联系游戏的支援团队？我多久可以收到回复？",
        desc: "<p>你可以使用游戏内反馈来给我们发送邮件。反馈按钮在游戏设置菜单内。我们会在24小时内回复你，取决于时区或其他因素影响。你也可以通过以下方式联系我们。</p><p>Facebook: <a href='https://www.facebook.com/NorthTowerGame'>https://www.facebook.com/NorthTowerGame</a></p><p>Twitter: <a href='https://twitter.com/NorthTowerGame'>https://twitter.com/NorthTowerGame</a></p>"
      },
      {
        id: 14,
        title: "我意外错买了游戏礼包，该如何获得退款？",
        desc: "你可以立即联系谷歌Play的支援团队申请退款。在购买后两小时内或至少不超过48小时通常不会导致你的退款申请失败。"
      },
      {
        id: 15,
        title: "我进游戏时显示的是错误的语言，应该在哪里改？",
        desc: "你可以在设置菜单中修改游戏语言（入口位置显示在下图红圈处）",
        img: [img151]
      },
      {
        id: 16,
        title: "我的游戏运行起来会卡顿或故障",
        desc: "此情况可能是由于网络连接问题或着你的手机负荷太高。请检查或更换你的网络环境。你也可以在设置菜单中降低分辨率和特效。"
      },
      {
        id: 17,
        title: "我该怎么将我的账号与谷歌账号连结？有什么必要吗？",
        desc: "你可以在完成少量教学后进入设置菜单并找到连结按钮。这很重要，因为如果你切换了设备或卸载了游戏，你可能失去你的账号数据。请连结你的账号以保护账号数据。如果你无法连结你的账号，请至少记住显示在设置菜单的你的领主ID。"
      },
      {
        id: 18,
        title: "我想更换与我账号连结的谷歌账号或是删除账号以重新开始，我该怎么做",
        desc: "你可以联系我们的支援团队来协助你。但必须通过游戏内反馈进行联系因为我们需要确认你拥有账号的所有权。"
      },
      {
        id: 19,
        title: "我在游戏内购买了礼包但没收到",
        desc: "通常这是由于延迟导致的。如果你长时间没有收到礼包的话，请联系我们的支援团队并发送谷歌订单号（GPA.XXX）来协助我们检查问题。"
      },
      {
        id: 20,
        title: "我看不了广告，这导致我没法获取相关奖励",
        desc: "这可能是由于多种原因造成的，像是连接问题或者你的设备屏蔽了广告。如果有些地区没有足够的广告供应商可能导致广告无法载入。你可以尝试使用VPN帮助你解决这个问题或是购买去除广告礼包来跳过看广告直接获得奖励。"
      },
      {
        id: 21,
        title: "反馈BUG有奖励拿吗？",
        desc: "当然。如果你反馈的BUG被确认存在，你将得到一份奖励，数量基于此BUG的影响程度和你反馈时的细节多寡。所以反馈BUG时附带截图或录屏会更好。"
      }
    ]
  }
}
export default message