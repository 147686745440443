export default {
    setLang(state, locale) {
        if (state.locales.includes(locale)) {
          state.locale = locale
        }
      },
       /**
     * 设置全局token值
     * @param state 全局对象
     * @param token 登录后返回的token值
     * @author Lily
     */
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("token", state.token);
  },
  /**
   * 设置全局token值
   * @param state 全局对象
   * @param user 用户信息
   * @author Lily
   */
  setUser(state, user) {
      state.user = user;
      localStorage.setItem("user", JSON.stringify(state.user));
  },
};