import img41 from '../../assets/images/faq/4-1.png'
import img51 from '../../assets/images/faq/5-1.png'
import img52 from '../../assets/images/faq/5-2.png'
import img151 from '../../assets/images/faq/15-1.png'
const message = {
  index: {
    pageName: 'ÍNDICE',
    gamefeatures: 'FUNÇÕES DO JOGO'
  },
  shop: {
    pageName: 'LOJA',
  },
  faq: {
    pageName: 'PERGUNTAS FREQUENTES',
    faqList: [
      {
        id: 1, 
        title: " A mira manual do dragão é cansativa. Existe uma forma mais fácil de jogar?",
        desc: "Sim. Você pode escolher o modo de Batalha Automática durante vários modos de combate. Ele fará com que o dragão escolha o alvo automaticamente e mude o alvo a derrotar o anterior. Você ainda pode escolher o alvo manualmente para lidar com inimigos mais perigosos sem que isso interrompa o modo de combate automático. Não se esqueça de conjurar habilidades. Elas ainda precisam ser controladas manualmente. Você pode desbloquear esta função após concluir a área 2 do Reino do Vento no nível 4."
      },
      {
        id: 2, 
        title: "Existe alguma função útil para desbloquear e que ajude a acelerar a jogabilidade?",
        desc: "Além da Batalha Automática que pode ser desbloqueada através da recompensa por níveis, você pode desbloquear a maior parte das outras funções através das recompensas de nível VIP, como o desafio rápido de saque, aceleração de batalha e o desafio rápido Chefe do Mundo."
      },
      {
        id: 3,
        title: ' Meu dragão não causa nenhum dano quando eu escolho um alvo.',
        desc: "O dragão realiza ataques balísticos. Por isso, se houver algum outro inimigo (como uma máquina de cerco) na frente do alvo, ela irá bloquear o ataque do seu dragão. Para lidar com esse problema, tente usar mais ataques em área."
      },
      {
        id: 4,
        title: "Apertar o botão de compra de torres não é muito eficiente. Como posso melhorar?",
        desc: "Você pode escolher o nível de estrela das torres que você quer comprar. Pressione o botão de estrela no canto inferior direito da página de edição de torre para escolher. Você precisa possuir torres com mais estrelas para desbloquear torres com maiores níveis de estrela. Você pode pressionar para desbloquear o requerimento. (O botão de acesso está marcado no círculo vermelho da imagem abaixo.)",
        img: [
          img41
        ]
      },
      {
        id: 5,
        title: "Eu tenho muitas torres inúteis. Como posso lidar com elas?",
        desc: "Você pode reciclá-las combinando-as em torres superiores. Toque no ícone de reciclagem no lado direito da página de edição de torres para entrar na página de combinação. Você pode conferir a probabilidade de cada resultado se você escolher três torres com diferentes níveis de estrelas. Toque no botão com o ponto de interrogação para ver mais guias.  (O botão de acesso está marcado no círculo vermelho da imagem abaixo.)",
        img: [
          img51,
          img52
        ]
      },
      {
        id: 6,
        title: "Como jogadores mais fracos puderam me vencer na arena ou na Guerra da Ilha da Liga?",
        desc: "O resultado da batalha não depende apenas do número do poder. Ele também é influenciado pelas combinações feitas pelos jogadores. Por exemplo, alguns jogadores que investiram pesado em alguns elementos específicos da equipe podem parecer fracos, mas eles têm força o suficiente para enfrentar jogadores com poder alto."
      },
      {
        id: 701, // 补充的一条
        title: "Como devo lidar com jogadores trapaceiros na arena ou no Chefe do Mundo?",
        desc: "Você pode denunciá-los à nossa equipe de suporte. Você pode encontrar o jogador trapaceiro na página de registros da arena e na página de classificação do Chefe do Mundo. Aperte “Denunciar” para nos enviar um e-mail. Se a trapaça for confirmada, iremos emitir um aviso ao jogador em questão. Se ele continuar com esse comportamento, sua conta será bloqueada por 3 dias e a punição será aumentada caso ele não pare de trapacear."
      },
      {
        id: 7,
        title: "Eu elevei meu palácio, mas minha produção de recursos não aumentou.",
        desc: "A elevação do palácio aumenta apenas a capacidade de armazenamento de cada recurso. Para aumentar a produção de recurso, você deve jogar o modo Portal do Impasse para ganhar mais construções nas suas ilhas e elevá-las."
      },
      {
        id: 8,
        title: "Nosso líder da liga não entrou no jogo há vários dias e não podemos iniciar a guerra das ilhas da liga.",
        desc: "Por enquanto, após dez dias de inatividade, o líder da liga será expulso automaticamente. Estamos planejando um sistema de votação de expulsão para melhorar esse problema."
      },
      {
        id: 9,
        title: " Meu progresso no Campo de Treinamento será perdido quando eu sair da liga?",
        desc: "Não. O nível do campo de treinamento está vinculado a você e não à liga e todos têm sua própria progressão. Mas você perderá os bônus até entrar na próxima liga."
      },
      {
        id: 10,
        title: " Por que a produção de suprimentos para resgatar nas ilhas às vezes é maior do que a minha produção total?",
        desc: "Se sua liga conquistou uma nova ilha, a produção do fornecedor que pode ser resgatada aumentará imediatamente. Mas a produção total não."
      },
      {
        id: 11,
        title: "Se eu entrar/sair de uma liga durante a Guerra das Ilhas da Liga. O que isso acarreta?",
        desc: "Ao entrar/sair de uma liga, você não poderá participar da Guerra das Ilhas da Liga se ela já tiver começado e, logo, não receberá nenhuma recompensa por ela."
      },
      {
        id: 12,
        title: "Como aumentar a chance de vencer a Guerra das Ilhas da Liga?",
        desc: "O vencedor da Guerra das Ilhas da Liga é definido pela pontuação marcada pela sua liga. Assim, o poder não é o único elemento da vitória, mas o esforço. Se sua liga puder se concentrar no uso de Rum (energia da Guerra das Ilhas da Liga) e conquistar as ilhas que fornecem mais pontuações, as chances de ganhar aumentarão."
      },
      {
        id: 13,
        title: "Como posso entrar em contato com o suporte do jogo se eu tiver um problema? O quanto devo esperar por uma resposta?",
        desc: "<p>Você pode usar o contato dentro do jogo para nos mandar uma mensagem. O botão se encontra no menu de configurações do jogo. Responderemos dentro de 24 horas, dependendo do fuso horário e outras circunstâncias. Você também pode entrar em contato através das formas abaixo: </p><p>Facebook: <a href='https://www.facebook.com/NorthTowerGame'>https://www.facebook.com/NorthTowerGame</a></p><p>Twitter: <a href='https://twitter.com/NorthTowerGame'>https://twitter.com/NorthTowerGame</a></p>"
      },
      {
        id: 14,
        title: "Eu comprei acidentalmente o pacote errado no jogo. Posso obter um reembolso?",
        desc: "Você pode pedir ao suporte do Google Play por um reembolso imediato. Normalmente, solicitações em até duas horas e não mais de 48 horas após a comprar são atendidas."
      },
      {
        id: 15,
        title: "Eu entrei no jogo com o idioma errado. Onde posso mudá-lo?",
        desc: "Você pode mudar o idioma do jogo no menu de configurações. (O botão de acesso está marcado no círculo vermelho da imagem abaixo.)",
        img: [img151]
      },
      {
        id: 16,
        title: "Meu jogo apresenta travamentos e falhas.",
        desc: "Talvez seja problemas de conexão ou seu celular esteja sobrecarregado. Verifique ou mude a sua conexão. Você também pode diminuir o nível de definição dos efeitos do jogo no menu de configurações."
      },
      {
        id: 17,
        title: "Como posso vincular minha conta do Google ao jogo e por que eu deveria fazer isso?",
        desc: "Você pode entrar no menu de configurações após concluir alguns tutoriais. Isso é importante, pois se você mudar de dispositivo ou desinstalar o jogo, talvez você perca os dados da sua conta. Vincule sua conta para proteger os dados do jogo. Ou pelo menos memorize o seu ID de lorde que é exibido no menu de configurações caso não consiga vincular a sua conta."
      },
      {
        id: 18,
        title: "Quero alterar a conta do Google vinculada à minha conta do jogo ou excluir minha conta do jogo para começar de novo. O que devo fazer?",
        desc: "  Você pode pedir ajuda à equipe de suporte. Mas apenas por meio do contato no jogo, pois precisamos verificar a sua autoridade sobre a conta."
      },
      {
        id: 19,
        title: "Comprei um pacote no jogo, mas não o recebi.",
        desc: "Isso geralmente é causado por um atraso. Se você ainda não receber o pacote por um longo tempo, entre em contato com nossa equipe de suporte com o número da fatura do Google Play (GPA.XXX) para verificarmos."
      },
      {
        id: 20,
        title: "Não consigo assistir a anúncios para resgatar recompensas.",
        desc: "Isso pode ocorrer por vários motivos, como problemas de conexão ou bloqueadores de anúncios no seu dispositivo. E se algumas áreas não tiverem provedores de anúncios suficientes, pode ser que não haja anúncios para serem carregados. Você pode tentar usar um VPN para ajudar a resolver o problema de carregamento ou comprar o pacote de remoção de anúncios para resgatar suas recompensas sem assisti-los."
      },
      {
        id: 21,
        title: "Existe alguma recompensa por relatar um bug?",
        desc: "Com certeza. Se o bug que você relatou for confirmado, você receberá recompensas com base no efeito do bug e em quão detalhada foi a sua descrição fornecida. Portanto, relatar bugs com capturas de tela ou vídeos será melhor."
      }
    ]
  }
}
export default message