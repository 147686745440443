import img41 from '../../assets/images/faq/4-1.png'
import img51 from '../../assets/images/faq/5-1.png'
import img52 from '../../assets/images/faq/5-2.png'
import img151 from '../../assets/images/faq/15-1.png'
const message = {
  index: {
    pageName: 'INDEX',
    gamefeatures: 'THE GAME FEATURES'
  },
  shop: {
    pageName: 'SHOP',
  },
  faq: {
    pageName: 'FAQ',
    faqList: [
      {
        id: 1, 
        title: "The dragon's aiming manually makes me tired. Is there an easier way to play?",
        desc: "Yes. You can choose to use auto-battle during multi kinds of modes of combat. It will make your dragon automatically choose the target and change when the target's killed. You still can choose the target by yourself to deal with high-threat enemies and it won't interrupt the auto-combat mode. Don't forget to cast the spells, they still need you to manually control. You can unlock this function after finishing Windland area 2 level 4."
      },
      {
        id: 2, 
        title: "What convenient function can be unlocked to speed up my gameplay?",
        desc: "Except the auto-battle can be unlocked from level rewards. You can unlock most other functions from the VIP level rewards. Including plunder quick challenge, battle speedup and World Boss quick challenge. "
      },
      {
        id: 3,
        title: 'I chose the target but my dragon is not dealing any damage to it.',
        desc: "The dragon's attack actually has ballistic. So if there's is another enemy(like a siege machine) in front of the target it will block your dragon's attack. To handle this problem, try use more AOE attacks might be useful"
      },
      {
        id: 4,
        title: "Spamming the tower purchase button is low efficiency. How can I improve?",
        desc: "You can choose the stars of the tower you want to purchase. Press the star button on the right bottom of the tower edit page then you can choose. Higher starting stars requires you to unlock by getting a high stars tower. You can press to check the unlock requirement. ",
        img: [
          img41
        ]
      },
      {
        id: 5,
        title: "I have too many useless towers. How can I deal with them?",
        desc: "You can recycle them by combining them for a higher-quality tower. Press the recycle mark button on the middle right of the tower edit page to enter the combine page. You can see the probability of each result if you chose three towers that have different stars. And you can press the question mark button to see more guides.",
        img: [
          img51,
          img52
        ]
      },
      {
        id: 6,
        title: "Why could I defeat by other players weaker than me in the arena or league island war?",
        desc: "The battle result does not only depend on the number of power. It will also be affected by the combinations between you and other players. For example, some players highly invested in a few parts of their team may make them look like weak but have the strength to counter higher-power players."
      },
      {
        id: 701, // 补充的一条
        title: "How should I deal with a cheated player in the arena or the world boss",
        desc: "You can report them to our support team. You can find the cheated player on the log page in the arena and on the ranking page in the world boss. Then press \"report\" to send us mail. If we confirmed cheating behavior, first we will warn the cheated player. If he continued cheating he will get 3 days of account blocking. And the punishment will keep increasing if he's not repentant."
      },
      {
        id: 7,
        title: "I upgraded my palace but my resource production didn't increase.",
        desc: "The palace upgrading will only increase the storage capacity of each resource. To increase resource production you have to play the portal of impasse mode to gain more buildings that can be placed on your islands and upgrade them."
      },
      {
        id: 8,
        title: "Our league leader has been not logged into the game for several days and we can't launch the league islands war.",
        desc: "For now, after ten days of not logging in the league leader will be automatically kicked. We are planning on a vote kick system to improve this problem."
      },
      {
        id: 9,
        title: "Will my progression on training camp be lost when I quit the league?",
        desc: "No, you won't. The training camp level is linked with you not the league and everyone has their own progression. But you will lose the buffs of it until you join the next league."
      },
      {
        id: 10,
        title: "Why are the islands supplies yield to claim sometimes higher than my total yield?",
        desc: "If your league has conquered a new islands the supplier yield to claim will immediately increase. But the total yield won't."
      },
      {
        id: 11,
        title: "If I join/quit a league during the league islands war. What will it cost?",
        desc: "When you join/quit a league you won't be able to join the league islands war if it’s already started and won’t receive any rewards from it."
      },
      {
        id: 12,
        title: "How to increase the chance of winning the league islands war?",
        desc: "The league islands war's winner is decided by the score your league gained. So power is not the only element of victory but the effort. If your league can focus on the use of Rum（league islands war stamina) and conquer the islands that provide more scores will increase your chance to win."
      },
      {
        id: 13,
        title: "How can I contact the game's support team if I met a problem? How long will I receive a reply?",
        desc: "<p>You can use in-game feedback to mail us. The button is in the game settings menu. We will reply to you within 24 hours, depending on the time zone and other effects. You can also contact us through the ways below.</p><p>Facebook: <a href='https://www.facebook.com/NorthTowerGame'>https://www.facebook.com/NorthTowerGame</a></p><p>Twitter: <a href='https://twitter.com/NorthTowerGame'>https://twitter.com/NorthTowerGame</a></p>"
      },
      {
        id: 14,
        title: "I accidentally bought the wrong pack in the game. How can I get a refund?",
        desc: "You can ask the Google Play support team for a refund immediately. Within two hours and don't over 48 hours after purchase usually won't cause your refund request to fail."
      },
      {
        id: 15,
        title: "I entered the game with the wrong language. Where can I change it?",
        desc: "You can change your game's language in the settings menu.",
        img: [img151]
      },
      {
        id: 16,
        title: "My game runs with lag or glitches.",
        desc: " A connection problem may cause it or your cellphone is in high load. Please check or change your connection. You can lower the level of definition and effects in the settings menu."
      },
      {
        id: 17,
        title: "How should I link my account with my Google account and why should I do it?",
        desc: "You can enter the settings menu after you finished few tutorial.  It's important because if you switch devices or uninstall the game, you may lose your account data. Please link your account to protect your game data. Or at least remember your lord ID shows on the settings menu if you can't link your account. "
      },
      {
        id: 18,
        title: "I want to change the Google account linked with my game account or delete my game account to start over. What should I do?",
        desc: " You can contact the support team to help you. But only via in-game feedback contact because we need to check the authority between you and the account."
      },
      {
        id: 19,
        title: "I bought a pack in the game but didn't receive it.",
        desc: "It's usually caused by a delay. If you still don't receive the pack for a long time. Please contact our support team with the Google Play bill number(GPA.XXX) to help do the checking."
      },
      {
        id: 20,
        title: "I can't watch advertisements to claim my rewards.",
        desc: "It could cause by several reasons. Like connection problems or your device may blocked it. And if some areas don't have enough advertisement providers may caused the advertisement can't be loaded. You can try using VPN to help you solve the loading problem or buy the remove ads pack to claim your rewards without watching them."
      },
      {
        id: 21,
        title: "Is there any reward to report a bug?",
        desc: "Of course. If the bug you reported has been confirmed. You will get rewards based on the effect of the bug and how detailed your description is provided. So reporting the bug with screenshots or videos will be better."
      }
    ]
  }
}
export default message