import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from "@/store";
import en from './lang/en'
import zh from './lang/zh'
import zht from './lang/zht'
import de from './lang/de'
import pt from './lang/pt'
import fr from './lang/fr'
import es from './lang/es'
import ja from './lang/ja'
import ko from './lang/ko'
import it from './lang/it'
import ru from './lang/ru'
import th from './lang/th'
import id from './lang/id'
import my from './lang/my'
import vi from './lang/vi'

Vue.use(VueI18n)

const messages = {
  en: {...en},
  zh: {...zh},
  zht: {...zht},
  de: {...de},
  pt: {...pt},
  fr: {...fr},
  es: {...es},
  ja: {...ja},
  ko: {...ko},
  it: {...it},
  ru: {...ru},
  th: {...th},
  id: {...id},
  my: {...my},
  vi: {...vi}
}
const i18n = new VueI18n({
  locale: store.state.locale,
  messages
})
export default i18n