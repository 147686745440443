import img41 from '../../assets/images/faq/4-1.png'
import img51 from '../../assets/images/faq/5-1.png'
import img52 from '../../assets/images/faq/5-2.png'
import img151 from '../../assets/images/faq/15-1.png'
const message = {
  index: {
    pageName: 'INDEX',
    gamefeatures: 'INDEXDIE SPIELFUNKTIONEN'
  },
  shop: {
    pageName: 'SHOP',
  },
  faq: {
    pageName: 'FAQ',
    faqList: [
      {
        id: 1, 
        title: "Das manuelle Zielen des Drachens macht mich müde. Gibt es einen einfacheren Weg zu spielen?",
        desc: "Ja. Du kannst festlegen, dass du in verschiedenen Kampfmodi den Auto-Kampf verwendest. Er sorgt dafür, dass dein Drache automatisch das Ziel auswählt und es ändert, wenn das Ziel getötet wird. Du kannst das Ziel immer noch selbst auswählen, um mit hochgefährlichen Gegnern fertig zu werden, und der Auto-Kampfmodus wird nicht unterbrochen. Vergiss nicht, die Zaubersprüche zu verwenden, du musst sie immer noch manuell steuern. Du kannst diese Funktion freischalten, nachdem du das Windland-Gebiet 2 Level 4 abgeschlossen hast."
      },
      {
        id: 2, 
        title: "Welche praktische Funktion kann freigeschaltet werden, um mein Gameplay zu beschleunigen?",
        desc: "Nur der Auto-Kampf kann durch Levelbelohnungen freigeschaltet werden. Die meisten anderen Funktionen kannst du mit den VIP-Levelbelohnungen freischalten. Inklusive Plünderungs-Schnellherausforderung, Kampfbeschleunigung und Weltboss-Schnellherausforderung."
      },
      {
        id: 3,
        title: 'Ich habe das Ziel ausgewählt, aber mein Drache fügt ihm keinen Schaden zu.',
        desc: "Der Angriff des Drachens ist eigentlich ballistisch. Wenn sich also ein anderer Feind (z. B. eine Belagerungsmaschine) vor dem Ziel befindet, blockiert er den Angriff deines Drachens. Um dieses Problem zu lösen, könnte es sinnvoll sein, mehr AOE-Angriffe einzusetzen."
      },
      {
        id: 4,
        title: "Die Turmkauf-Taste wiederholt zu drücken, ist wenig effizient. Wie kann ich mich verbessern?",
        desc: "Du kannst die Sterne des Turms auswählen, den du kaufen willst. Drücke die Sterntaste rechts unten auf der Turmbearbeitungsseite, dann kannst du wählen. Höhere Startsterne erfordern, dass du einen hohen Turm mit hohen Sternen freischaltest. Du kannst drücken, um die Freischaltbedingungen zu überprüfen. (Die Eingangsposition ist der rote Kreis auf dem Bild unten)",
        img: [
          img41
        ]
      },
      {
        id: 5,
        title: "Ich habe zu viele nutzlose Türme. Wie kann ich mit ihnen umgehen?",
        desc: "Du kannst sie recyceln, indem du sie zu einem höherwertigen Turm kombinierst. Drücke auf die Recyclingmarkierungs-Taste in der Mitte rechts auf der Turmbearbeitungsseite, um die Kombinierseite aufzurufen. Du kannst die Wahrscheinlichkeit jedes Ergebnisses sehen, wenn du drei Türme wählst, die unterschiedliche Sterne haben. Und du kannst die Fragezeichen-Taste drücken, um weitere Leitfäden zu sehen. (Die Eingangsposition ist der rote Kreis auf dem Bild unten)",
        img: [
          img51,
          img52
        ]
      },
      {
        id: 6,
        title: "Warum konnte ich andere Spieler, die schwächer waren als ich, in der Arena oder im Liga-Inselkrieg besiegen",
        desc: "Das Kampfergebnis hängt nicht nur von der Höhe der Stärke ab. Sie wird auch von den Kombinationen zwischen dir und anderen Spielern beeinflusst. So können beispielsweise einige Spieler, die sehr stark in einige Teile ihres Teams investiert haben, diese als schwach erscheinen lassen, obwohl sie die Kraft haben, stärkeren Spielern etwas entgegenzusetzen."
      },
      {
        id: 701, // 补充的一条
        title: "Wie sollte ich mit einem betrügenden Spieler in der Arena oder in Weltboss umgehen?",
        desc: 'Du kannst sie an unser Kundendienstteam melden. Du kannst den betrügenden Spieler auf der Protokollseite in der Arena und auf der Ranglistenseite in Weltboss finden. Drücke dann auf "melden", um uns eine Mail zu schicken. Wenn wir ein betrügerisches Verhalten bestätigen, werden wir zunächst den betrügende Spieler verwarnen. Wenn er weiter betrügt, wird sein Konto für 3 Tage gesperrt. Und die Strafe wird sich weiter erhöhen, wenn er keine Reue zeigt'
      },
      {
        id: 7,
        title: "Ich habe meinen Palast aufgewertet, aber meine Ressourcenproduktion ist nicht gestiegen.",
        desc: "Ich habe meinen Palast aufgewertet, aber meine Ressourcenproduktion ist nicht gestiegen.Durch das Aufwerten des Palastes wird lediglich die Lagerkapazität der einzelnen Ressourcen erhöht. Um die Ressourcenproduktion zu erhöhen, musst du den Modus Portal der Pattsituation spielen, um mehr Gebäude zu erhalten, die du auf deinen Inseln platzieren und aufwerten kannst."
      },
      {
        id: 8,
        title: "Unser Ligaanführer hat sich seit mehreren Tagen nicht mehr im Spiel angemeldet und wir können den Liga-Inselkrieg nicht starten.",
        desc: "Im Moment wird der Ligaanführer nach zehn Tagen ohne Anmeldung automatisch aus der Liga geworfen. Wir planen ein Abstimmungs-Kick-System, um dieses Problem zu verbessern."
      },
      {
        id: 9,
        title: "Geht mein Fortschritt im Trainingslager verloren, wenn ich die Liga verlasse?",
        desc: "Nein, geht er nicht. Das Trainingslagerlevel ist mit dir und nicht mit der Liga verbunden und jeder hat seinen eigenen Fortschritt. Aber du verlierst die Vorteile, bis du der nächsten Liga beitrittst."
      },
      {
        id: 10,
        title: "Warum sind die Insel-Vorratserträge manchmal höher als mein Gesamtertrag?",
        desc: "Wenn deine Liga eine neue Insel erobert hat, steigt der Vorratsertrag sofort an. Aber der Gesamtertrag nicht."
      },
      {
        id: 11,
        title: "Wenn ich während des Liga-Inselkriegs einer Liga beitrete/sie verlasse. Was wird es kosten?",
        desc: "Wenn du einer Liga beitrittst/sie verlässt, kannst du nicht mehr am Liga-Inselkrieg teilnehmen, wenn er bereits begonnen hat, und du erhältst keine Belohnungen mehr."
      },
      {
        id: 12,
        title: "Wie kann man die Chance erhöhen, den Liga-Inselkrieg zu gewinnen?",
        desc: "Der Sieger des Liga-Inselkriegs wird durch die Punktzahl deiner Liga bestimmt. Die Stärke ist also nicht das einzige Element des Sieges, sondern auch der Einsatz. Wenn deine Liga sich auf den Einsatz von Rum (Liga-Inselkriegsausdauer) konzentrieren kann und die Inseln erobert, die mehr Punkte gewähren, erhöht sich deine Chance zu gewinnen."
      },
      {
        id: 13,
        title: "Wie kann ich das Kundendienstteam des Spiels kontaktieren, wenn ich auf ein Problem stoße? Wie lange dauert es, bis ich eine Antwort erhalte?",
        desc: "<p>Du kannst uns über das Feedback im Spiel eine Mail schicken. Die Taste befindet sich im Menü der Spieleinstellungen. Wir werden dir innerhalb von 24 Stunden antworten, abhängig von der Zeitzone und anderen Faktoren. Du kannst uns auch über die unten stehenden Wege kontaktieren.</p><p>Facebook: <a href='https://www.facebook.com/NorthTowerGame'>https://www.facebook.com/NorthTowerGame</a></p><p>Twitter: <a href='https://twitter.com/NorthTowerGame'>https://twitter.com/NorthTowerGame</a></p>"
      },
      {
        id: 14,
        title: "Ich habe versehentlich das falsche Paket im Spiel gekauft. Wie kann ich eine Rückerstattung erhalten?",
        desc: "Du kannst das Google Play Kundendienstteam sofort um eine Rückerstattung bitten. Innerhalb von zwei Stunden und nicht über 48 Stunden nach dem Kauf wird dein Rückerstattungsantrag in der Regel nicht abgelehnt."
      },
      {
        id: 15,
        title: "Ich habe das Spiel mit der falschen Sprache begonnen. Wo kann ich sie ändern?",
        desc: "Du kannst die Sprache deines Spiels im Einstellungsmenü ändern. (Die Eingangsposition ist der rote Kreis auf dem Bild unten.)",
        img: [img151]
      },
      {
        id: 16,
        title: " Mein Spiel läuft mit Verzögerungen oder Störungen.",
        desc: " Die Ursache kann ein Verbindungsproblem sein oder dein Mobiltelefon ist überlastet. Bitte überprüfe oder ändere deine Verbindung. Du kannst die Schärfe und die Effekte im Einstellungsmenü verringern."
      },
      {
        id: 17,
        title: "Wie sollte ich mein Konto mit meinem Google-Konto verknüpfen und warum sollte ich das tun?",
        desc: "Du kannst das Einstellungsmenü aufrufen, nachdem du ein paar Tutorials beendet hast.  Das ist wichtig, denn wenn du das Gerät wechselst oder das Spiel deinstallierst, kannst du deine Kontodaten verlieren. Bitte verknüpfe dein Konto, um deine Spieldaten zu schützen. Oder merke dir zumindest deine Lord ID, die im Einstellungsmenü angezeigt wird, wenn du dein Konto nicht verknüpfen kannst. "
      },
      {
        id: 18,
        title: "Ich möchte das mit meinem Spielkonto verknüpfte Google-Konto ändern oder mein Spielkonto löschen, um neu zu beginnen. Was sollte ich tun?",
        desc: "Du kannst dich an das Kundendienstteam wenden, das dir hilft. Aber nur über den Feedback-Kontakt im Spiel, weil wir die Verbindung zwischen dir und dem Konto überprüfen müssen."
      },
      {
        id: 19,
        title: "Ich habe im Spiel ein Paket gekauft, es aber nicht erhalten.",
        desc: "Die Ursache ist meist eine Verzögerung. Wenn du das Paket noch immer nicht erhalten hast. Bitte kontaktiere unser Kundendienstteam mit der Google Play Rechnungsnummer (GPA.XXX), damit wir die Überprüfung durchführen können."
      },
      {
        id: 20,
        title: "Ich kann mir keine Werbung ansehen, um meine Belohnungen einzufordern.",
        desc: "Dafür kann es mehrere Gründe geben. Zum Beispiel bei Verbindungsproblemen oder wenn dein Gerät es blockiert. Und wenn in einigen Bereichen nicht genügend Werbeanbieter vorhanden sind, kann es sein, dass die Werbung nicht geladen werden kann. Du kannst versuchen, das Ladeproblem per VPN zu lösen oder das Paket zum Entfernen von Werbung kaufen, um deine Belohnungen zu erhalten, ohne sie zu sehen."
      },
      {
        id: 21,
        title: "Gibt es eine Belohnung für die Meldung eines Fehlers?",
        desc: "Ja, natürlich. Wenn der von dir gemeldete Fehler bestätigt wurde. Du erhältst Belohnungen, die von der Auswirkung des Fehlers abhängen und davon, wie detailliert deine Beschreibung ist. Daher ist es besser, den Fehler mit Screenshots oder Videos zu melden."
      }
    ]
  }
}
export default message