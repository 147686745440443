import img41 from '../../assets/images/faq/4-1.png'
import img51 from '../../assets/images/faq/5-1.png'
import img52 from '../../assets/images/faq/5-2.png'
import img151 from '../../assets/images/faq/15-1.png'
const message = {
  index: {
    pageName: 'УКАЗАТЕЛЬ',
    gamefeatures: 'ОСОБЕННОСТИ ИГРЫ'
  },
  shop: {
    pageName: 'МАГАЗИН',
  },
  faq: {
    pageName: 'ЧаВо',
    faqList: [
      {
        id: 1, 
        title: "Мне надоело постоянно выбирать цели дракону. Можно ли это как-то упростить?",
        desc: "Да. Во многих режимах игры вам доступен автобой. При его использовании дракон выбирает цель автоматически и переключается на другую, как только эта погибает. Вы по-прежнему можете выбирать цель самостоятельно, чтобы справиться с опасными врагами, и это не прервет режим автобоя. Не забывайте использовать заклинания, это по-прежнему делается вручную. Вы можете разблокировать эту функцию после завершения 4 уровня 2 области в Стране Ветров."
      },
      {
        id: 2, 
        title: "Какие существуют удобные функции, позволяющие ускорить игровой процесс?",
        desc: "Автобой открывается в качестве награды за прохождение уровня. Большинство других функций можно получить из наград VIP-уровня. В том числе быстрый вызов грабежа, ускорение битвы и быстрый вызов мирового босса. "
      },
      {
        id: 3,
        title: 'Я выбрал цель, но мой дракон не наносит ей никакого урона.',
        desc: "Атака дракона считается баллистической. Так что если перед целью находится другой враг (например, осадная машина), он будет блокировать атаку дракона. Этого можно попробовать избежать, используя больше многоцелевых атак."
      },
      {
        id: 4,
        title: "Меня не устраивает качество выпадающих башен при использовании кнопки для их получения. Можно ли это исправить?",
        desc: "Вы можете выбрать уровень звездности башни, которую хотите купить. Нажмите на звезду в правой нижней части страницы изменения башен и сделайте выбор. Более высокий начальный уровень звездности доступен при получении соответствующей башни. Нажмите на кнопку, чтобы проверить требование для разблокировки (нужная кнопка обведена красным на картинке ниже).",
        img: [
          img41
        ]
      },
      {
        id: 5,
        title: "У меня слишком много бесполезных башен. Как мне это исправить?",
        desc: "Вы можете переработать их, объединив в башню более высокого качества. Нажмите на значок переработки в средней правой части страницы изменения башен, чтобы перейти на страницу объединения. Вы увидите вероятность получения каждого результата при выборе трех башен с разными звездами. А также вы можете нажать на знак вопроса и узнать больше подробностей (нужная кнопка обведена красным на картинке ниже).",
        img: [
          img51,
          img52
        ]
      },
      {
        id: 6,
        title: "Почему я проигрываю игрокам, которые слабее меня, на арене или в островной войне лиги?",
        desc: "Результат битвы зависит не только от вашей силы. На него также влияют сочетания башен у вас и других игроков. Например, некоторые игроки, сильно развившие некоторые элементы своей команды, могут казаться слабыми, но при этом им хватит сил противостоять игрокам с более высоким показателем силы."
      },
      {
        id: 701, // 补充的一条
        title: "Как мне поступить при встрече с игроком-читером на арене или мировом боссе?",
        desc: 'Вы можете сообщить об этом в нашу службу поддержки. Найдите игрока-читера в записях на арене и на вкладке с рейтингом у мирового босса. Затем нажмите "Отчет", чтобы отправить нам письмо. В случае подтверждения жульничества мы отправим игроку-читеру предупреждение. Если он не прекратит жульничать, его аккаунт будет заблокирован на 3 дня. И срок наказания будет увеличиваться до тех пор, пока игрок не прекратит жульничать.'
      },
      {
        id: 7,
        title: "Я улучшил свой замок, но производство ресурсов не увеличилось.",
        desc: "Улучшение замка увеличивает только емкость хранилища для каждого ресурса. Для увеличения производства ресурсов играйте в режиме портала тупика, и вы получите больше зданий, которые можно размещать на острове и улучшать."
      },
      {
        id: 8,
        title: "Лидер нашей лиги не заходит в игру уже несколько дней, и мы не можем начать островную войну лиги.",
        desc: "В настоящий момент, после десяти дней отсутствия в игре, лидер лиги будет автоматически исключен. Мы планируем ввести систему голосований для решения этой проблемы."
      },
      {
        id: 9,
        title: "Потеряю ли я свой прогресс в тренировочном лагере, если выйду из лиги?",
        desc: "Нет, не потеряете. Уровень тренировочного лагеря связан с вами, а не с лигой, и у каждого там собственный прогресс. Однако вы не будете получать баффы, которые он вам дает, пока не присоединитесь к следующей лиге."
      },
      {
        id: 10,
        title: "Почему доход с захваченных островов иногда выше моего общего дохода?",
        desc: "Если ваша лига захватила новый остров, то доход от поставщиков сразу же увеличится. Но общий доход не увеличится."
      },
      {
        id: 11,
        title: "Если я присоединюсь к лиге или выйду из лиги во время островной войны лиги, какие будут последствия?",
        desc: "Если вы присоединитесь к лиге или выйдете из лиги, вы не сможете присоединиться к островной войне лиги, если она уже началась, и не получите за нее никаких наград."
      },
      {
        id: 12,
        title: "Как увеличить шансы на победу в островной войне лиги?",
        desc: "Победитель в островной войне лиги определяется по количеству очков, набранных вашей лигой. Поэтому для победы важна не только сила, но и приложенные усилия. Если вашей лиге удается сосредоточиться на использовании рома (выносливость в островной войне лиги) и захвате островов, которые приносят больше очков, это увеличит ваши шансы на победу."
      },
      {
        id: 13,
        title: "Как мне связаться со службой поддержки игры, если у меня возникла проблема? Как скоро я получу ответ?",
        desc: "<p>Вы можете воспользоваться внутриигровой формой для обратной связи. Нужная кнопка находится в меню настроек игры. Мы ответим вам в течение 24 часов, в зависимости от часового пояса и других факторов. Вы также можете связаться с нами следующими способами.</p><p>Facebook: <a href='https://www.facebook.com/NorthTowerGame'>https://www.facebook.com/NorthTowerGame</a></p><p>Twitter: <a href='https://twitter.com/NorthTowerGame'>https://twitter.com/NorthTowerGame</a></p>"
      },
      {
        id: 14,
        title: "Я случайно купил не тот игровой набор. Как это отменить?",
        desc: "Вы можете немедленно обратиться в службу поддержки Google Play с просьбой о возврате денег. Запрос, поданный в течение 2 часов (и не более 48 часов) после покупки, как правило удовлетворяют и осуществляют возврат средств."
      },
      {
        id: 15,
        title: "При входе в игру у меня установился неверный язык. Где это можно изменить?",
        desc: "Язык игры можно изменить в меню настроек (вход в меню обведен красным на изображении ниже).",
        img: [img151]
      },
      {
        id: 16,
        title: "Моя игра запускается с задержками или глюками.",
        desc: "Это может быть вызвано проблемой с соединением или высокой нагрузкой на ваш мобильный телефон. Проверьте или измените ваше соединение. Вы можете снизить уровень четкости и эффектов в меню настроек."
      },
      {
        id: 17,
        title: "Как привязать мой аккаунт к аккаунту Google и зачем это делать?",
        desc: "Войдите в меню настроек после того, как закончите обучение.  Это важно, потому что если вы поменяете устройство или удалите игру, вы можете потерять данные своего аккаунта. Привяжите аккаунт, чтобы защитить свои игровые данные. Или хотя бы запомните свой ID лорда, который отображается в меню настроек, если у вас не получается привязать аккаунт. "
      },
      {
        id: 18,
        title: "Я хочу изменить аккаунт Google, привязанный к моему игровому аккаунту, или удалить свой игровой аккаунт, чтобы начать играть сначала. Что мне делать?",
        desc: "Вы можете обратиться в службу поддержки за помощью. Но только через внутриигровую обратную связь, потому что нам нужно удостовериться, ваш ли это аккаунт."
      },
      {
        id: 19,
        title: "Я купил игровой набор, но не получил его.",
        desc: "Обычно это происходит по причине задержки. Если вы все еще не получили набор в течение длительного времени, свяжитесь с нашей службой поддержки, указав номер счета Google Play (GPA.XXX), чтобы помочь провести проверку."
      },
      {
        id: 20,
        title: "Я не могу посмотреть рекламу, чтобы получить вознаграждение.",
        desc: "Это может быть вызвано несколькими причинами. Например, проблемы с подключением, или ваше устройство может ее блокировать. А если в некоторых регионах отсутствуют рекламные провайдеры, то реклама не может загрузиться. Вы можете попробовать использовать VPN, чтобы решить проблему загрузки, или купить пакет удаления рекламы, чтобы получать вознаграждения без ее просмотра."
      },
      {
        id: 21,
        title: "Предусмотрено ли вознаграждение за сообщение об ошибке?",
        desc: "Конечно. Если ошибка, о которой вы сообщили, была подтверждена. Вы получите вознаграждение в зависимости от вида ошибки и подробности предоставленного описания. Поэтому сообщения об ошибке стоит сопровождать скриншотами или видео."
      }
    ]
  }
}
export default message