import img41 from '../../assets/images/faq/4-1.png'
import img51 from '../../assets/images/faq/5-1.png'
import img52 from '../../assets/images/faq/5-2.png'
import img151 from '../../assets/images/faq/15-1.png'
const message = {
  index: {
    pageName: 'INDICE',
    gamefeatures: 'LE CARATTERISTICHE DEL GIOCO'
  },
  shop: {
    pageName: 'NEGOZIO',
  },
  faq: {
    pageName: 'FAQ',
    faqList: [
      {
        id: 1, 
        title: "La mira manuale del drago mi stanca. C'è un modo più semplice per giocare?",
        desc: "Sì. Puoi scegliere di usare la battaglia automatica durante più tipi di modalità di combattimento. Ciò farà scegliere il bersaglio al tuo drago automaticamente e lo cambierà quando il bersaglio sarà ucciso. Potrai ancora scegliere il bersaglio da solo per affrontare i nemici altamente minacciosi senza interrompere la modalità di combattimento automatico. Non dimenticare di lanciare gli incantesimi, hanno comunque bisogno che tu li controlli manualmente. Puoi sbloccare questa funzione dopo aver terminato Windland area 2 livello 4."
      },
      {
        id: 2, 
        title: "C'è una funzione utile  da sbloccare per velocizzare la mia giocabilità?",
        desc: "Esclusa la battaglia automatica che può essere sbloccata dalle ricompense livello. Puoi sbloccare la maggior parte delle altre funzioni dalle ricompense livello VIP. Comprese la sfida rapida saccheggio, l'accelerazione della battaglia e la sfida rapida Boss del Mondo. "
      },
      {
        id: 3,
        title: 'Ho scelto il bersaglio ma il mio drago non gli sta infliggendo alcun danno.',
        desc: "L'attacco del drago in realtà ha una balistica. Quindi, se c'è un altro nemico (come una macchina d'assedio) davanti al bersaglio, bloccherà l'attacco del tuo drago. Per gestire questo problema, potrebbe essere utile provare a utilizzare più attacchi AOE."
      },
      {
        id: 4,
        title: "Lo spam sul tasto di acquisto della torre è a bassa efficienza. Come posso migliorare?",
        desc: "Puoi scegliere le stelle della torre che desideri acquistare. Premi il tasto stella nella parte inferiore a destra della pagina di modifica della torre, quindi potrai scegliere. Le stelle iniziali più alte richiedono di essere sbloccate ottenendo una torre stelle alta. È possibile premere per verificare il requisito di sblocco. （La posizione per accedere è nel cerchio rosso nell'immagine qui sotto）",
        img: [
          img41
        ]
      },
      {
        id: 5,
        title: "Ho troppe torri inutili. Che ci posso fare?",
        desc: "Puoi riciclarle combinandole per una torre di qualità superiore. Premi il tasto con il segno del riciclo nella pagina di modifica della torre al centro a destra per accedere alla pagina della combinazione. Puoi vedere la probabilità di ogni risultato se hai scelto tre torri con stelle diverse. Puoi inoltre premere il tasto del punto interrogativo per vedere più guide. (La posizione per accedere è nel cerchio rosso nell'immagine qui sotto)",
        img: [
          img51,
          img52
        ]
      },
      {
        id: 6,
        title: "Perché potrei essere sconfitto da altri giocatori più deboli di me nell'arena o nella guerra dell'isola della lega?",
        desc: "Il risultato della battaglia non dipende solo dal numero di potenza. Sarà influenzato anche dalle combinazioni tra te e gli altri giocatori. Ad esempio, alcuni giocatori hanno investito molto in alcune parti della loro squadra che possono farli sembrare deboli ma hanno la forza di contrastare giocatori di potenza superiore."
      },
      {
        id: 701, // 补充的一条
        title: "Come dovrei comportarmi con un giocatore che ha imbrogliato nell'arena o con il boss del mondo?",
        desc: "Puoi segnalarli al nostro team di supporto. Puoi trovare il giocatore dell'imbroglio nella pagina del registro nell'arena e nella pagina della classifica del boss del mondo. Quindi premere 'segnala' per inviarci una mail. Se abbiamo confermato il comportamento di imbroglione, per prima cosa avviseremo il giocatore dell'imbroglio. Se ha continuato a barare, riceverà 3 giorni di blocco dell'account. E la punizione continuerà ad aumentare se non si pente."
      },
      {
        id: 7,
        title: "Ho aggiornato il mio palazzo ma la mia produzione di risorse non è aumentata.",
        desc: "L'aggiornamento del palazzo aumenterà solo la capacità di stoccaggio di ciascuna risorsa. Per aumentare la produzione di risorse devi giocare al portale della modalità impasse per acquisire più edifici che possono essere posizionati sulle isole e fare l'aggiornamento."
      },
      {
        id: 8,
        title: "Il nostro leader della lega non ha effettuato l’accesso al gioco per diversi giorni e non possiamo lanciare la guerra delle isole della lega.",
        desc: "Per ora, dopo dieci giorni di mancato accesso nella lega il leader verrà automaticamente espulso. Stiamo pianificando un sistema di voto di espulsione per migliorare questo problema."
      },
      {
        id: 9,
        title: "La mia progressione nel campo di addestramento andrà persa quando lascio la lega?",
        desc: "No, non la perderai. Il livello del campo di addestramento è collegato a te e non alla lega e ognuno ha la sua progressione. Però perderai i suoi buff finché non ti unirai alla prossima lega."
      },
      {
        id: 10,
        title: "Perché la resa delle forniture delle isole da reclamare a volte è superiore alla mia resa totale?",
        desc: "Se la tua lega ha conquistato una nuova isola, la resa del fornitore da reclamare aumenterà immediatamente. Però la resa totale no."
      },
      {
        id: 11,
        title: "Se mi unisco/abbandono una lega durante la guerra delle isole della lega. Cosa mi costerà?",
        desc: "Quando ti unisci/abbandoni una lega non potrai unirti alla guerra delle isole della lega se è già iniziata e non ne riceverai nessuna ricompensa."
      },
      {
        id: 12,
        title: "Come aumentare le possibilità di vincere la guerra delle isole della lega?",
        desc: "Il vincitore della guerra delle isole della lega è stabilito dal punteggio ottenuto dalla tua lega. Quindi la potenza non è l'unico elemento per la vittoria, ma lo sforzo. Se la tua lega può concentrarsi sull'uso del Rum (resistenza della guerra delle isole della lega) e conquistare le isole che forniscono più punteggi aumenterai le tue possibilità di vincere."
      },
      {
        id: 13,
        title: "Come posso contattare il team di supporto del gioco se ho riscontrato un problema? Entro quanto tempo riceverò una risposta?",
        desc: "<p>Puoi utilizzare il feedback nel gioco per inviarci un'e-mail. Il tasto si trova nel menu delle impostazioni di gioco. Ti risponderemo entro 24 ore, a seconda del fuso orario e di altri effetti. Puoi anche contattarci attraverso i seguenti modi.</p><p>Facebook: <a href='https://www.facebook.com/NorthTowerGame'>https://www.facebook.com/NorthTowerGame</a></p><p>Twitter: <a href='https://twitter.com/NorthTowerGame'>https://twitter.com/NorthTowerGame</a></p>"
      },
      {
        id: 14,
        title: "Per errore ho acquistato il pacchetto sbagliato nel gioco. Come posso ottenere un rimborso?",
        desc: "Puoi chiedere immediatamente un rimborso al team di supporto di Google Play. Entro due ore e non oltre 48 ore dopo l'acquisto di solito la richiesta di rimborso va a buon fine."
      },
      {
        id: 15,
        title: "Sono entrato nel gioco con la lingua sbagliata. Dove posso cambiarla?",
        desc: "Puoi cambiare la lingua nel gioco nel menu delle impostazioni. (La posizione per accedere è nel cerchio rosso nell'immagine qui sotto.)",
        img: [img151]
      },
      {
        id: 16,
        title: "Il mio gioco ha ritardi o glitch.",
        desc: "Potrebbero essere causati da un problema di connessione o il tuo cellulare è in condizioni di sovraccarico. Si prega di verificare o modificare la connessione. È possibile ridurre il livello di definizione e gli effetti nel menu delle impostazioni."
      },
      {
        id: 17,
        title: "Come devo collegare il mio account al mio account Google e perché dovrei farlo?",
        desc: "Puoi accedere al menu delle impostazioni dopo aver terminato alcuni tutorial. È importante perché se cambi dispositivo o disinstalli il gioco, potresti perdere i dati del tuo account. Collegare l'account per proteggere i propri dati di gioco. O almeno ricorda il tuo lord ID mostrato nel menu delle impostazioni se non riesci a collegare il tuo account. "
      },
      {
        id: 18,
        title: "Voglio cambiare l'account Google collegato al mio account di gioco o eliminare il mio account di gioco per ricominciare. Cosa dovrei fare?",
        desc: "Puoi contattare il team di supporto per aiutarti. Ma solo tramite il contatto di feedback all'interno del gioco perché dobbiamo verificare l'autorità tra te e l'account."
      },
      {
        id: 19,
        title: "Ho comprato un pacchetto nel gioco ma non l'ho ricevuto.",
        desc: "Di solito è causato da un ritardo. Se non hai ricevuto il pacchetto da molto tempo. Per favore contatta il nostro team di assistenza con il numero di fattura di Google Play (GPA.XXX) per aiutare a eseguire il controllo."
      },
      {
        id: 20,
        title: "Non posso guardare gli annunci per reclamare le ricompense.",
        desc: "Potrebbe essere causato da diversi motivi. Come problemi di connessione o il tuo dispositivo potrebbe bloccarli. E se alcune aree non hanno abbastanza fornitori di annunci pubblicitari, l'annuncio potrebbe non essere caricato. Puoi provare a utilizzare una VPN affinché ti aiuti a risolvere il problema di caricamento o acquistare il pacchetto di rimozione annunci per richiedere le tue ricompense senza guardarli."
      },
      {
        id: 21,
        title: "C'è qualche ricompensa per segnalare un bug?",
        desc: "Certo. Se il bug che hai segnalato è stato confermato. Riceverai ricompense in base all'effetto del bug e alla descrizione dettagliata fornita. Quindi segnalare il bug con gli screenshot o dei video sarà migliore."
      }
    ]
  }
}
export default message