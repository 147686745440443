import img41 from '../../assets/images/faq/4-1.png'
import img51 from '../../assets/images/faq/5-1.png'
import img52 from '../../assets/images/faq/5-2.png'
import img151 from '../../assets/images/faq/15-1.png'
const message = {
  index: {
    pageName: 'ÍNDICE',
    gamefeatures: 'CARACTERÍSTICAS DEL JUEGO'
  },
  shop: {
    pageName: 'TIENDA',
  },
  faq: {
    pageName: 'FAQ',
    faqList: [
      {
        id: 1, 
        title: "Apuntar manualmente con el dragón me cansa. ¿Hay alguna forma más fácil de jugar?",
        desc: "Sí. Puedes elegir utilizar la batalla automática en varios tipos de combate. Eso hará que tu dragón elija automáticamente el objetivo y cambie cuando el objetivo muera. Todavía podrás elegir el objetivo por ti mismo para hacer frente a los enemigos peligrosos y no interrumpirá el modo de auto-combate. No te olvides de lanzar los hechizos, todavía necesitarán que los controles manualmente. Puedes desbloquear esta función después de terminar el nivel 4 del área 2 de Luna."
      },
      {
        id: 2, 
        title: "¿Qué función puedo desbloquear para acelerar mi juego?",
        desc: "Excepto la batalla automática que se puede desbloquear con las recompensas de nivel, puedes desbloquear la mayoría de las otras funciones con las recompensas de nivel VIP. Incluyendo el desafío rápido de saqueo, la aceleración de la batalla y el desafío rápido del Jefe Mundial."
      },
      {
        id: 3,
        title: 'He elegido el objetivo, pero mi dragón no le hace ningún daño.',
        desc: "El ataque del dragón en realidad es balístico. Así que si hay otro enemigo (como una máquina de asedio) delante del objetivo, bloqueará el ataque de tu dragón. Para manejar este problema, tratar de usar más ataques AOE podría ser útil."
      },
      {
        id: 4,
        title: "El uso del botón de compra de la torre es poco eficaz. ¿Cómo puedo mejorar?",
        desc: "Puedes elegir las estrellas de la torre que quieres comprar. Pulsa el botón de las estrellas en la parte inferior derecha de la página de edición de la torre y podrás elegir. Las estrellas iniciales más altas requieren el desbloqueo consiguiendo una torre de estrellas altas. Puedes pulsar para comprobar los requisitos de desbloqueo. (La posición de entrada está en el círculo rojo en la imagen de abajo)",
        img: [
          img41
        ]
      },
      {
        id: 5,
        title: "Tengo demasiadas torres inútiles. ¿Qué puedo hacer con ellas?",
        desc: "Puedes reciclarlas combinándolas para obtener una torre de mayor calidad. Pulsa el botón de la marca de reciclaje en la parte central derecha de la página de edición de la torre para entrar en la página de combinación. Puedes ver la probabilidad de cada resultado si eliges tres torres que tengan diferentes estrellas. Pulsa el botón del signo de interrogación para ver más guías. (La posición de entrada está en el círculo rojo en la imagen de abajo)",
        img: [
          img51,
          img52
        ]
      },
      {
        id: 6,
        title: "¿Por qué soy derrotado por otros jugadores más débiles que yo en la arena o en la guerra de clanes de la isla?",
        desc: "El resultado de la batalla no sólo depende del poder. También se verá afectado por las combinaciones entre tú y otros jugadores. Por ejemplo, algunos jugadores que invierten mucho en algunas partes de su equipo pueden parecer débiles, pero tienen la fuerza necesaria para contrarrestar a jugadores de mayor poder."
      },
      {
        id: 701, // 补充的一条
        title: "¿Qué puedo hacer con un jugador tramposo en la arena o el jefe mundial?",
        desc: 'Puedes reportarlos a nuestro equipo de soporte. Puedes encontrar al jugador tramposo en la página de registro en la arena y en la página de clasificación del jefe mundial. A continuación, pulsa "reportar" para enviarnos un correo. Si confirmamos su mal comportamiento, primero advertiremos al jugador tramposo. Si sigue haciendo trampas, recibirá 3 días de bloqueo de la cuenta. El castigo seguirá aumentando si no se arrepiente.'
      },
      {
        id: 7,
        title: "He mejorado mi palacio pero mi producción de recursos no ha aumentado.",
        desc: "La mejora del palacio sólo aumentará la capacidad de almacenamiento de cada recurso. Para aumentar la producción de recursos tienes que jugar al modo portal de impasse para ganar más edificios que colocar en tus islas y mejorarlos."
      },
      {
        id: 8,
        title: "1.El líder de nuestro clan lleva varios días sin entrar en el juego y no podemos lanzar la guerra de clanes de la isla.",
        desc: "Por ahora, el líder del clan será expulsado automáticamente después de diez días de no conectarse. Estamos planeando un sistema de expulsión por votación para mejorar este problema."
      },
      {
        id: 9,
        title: "¿Se perderá mi progreso en el campo de entrenamiento si abandono el clan?",
        desc: "No. El nivel del campo de entrenamiento está ligado a ti, no al clan, y cada uno tiene su propio progreso. Pero perderás las bonificaciones del mismo hasta que te unas a otro clan."
      },
      {
        id: 10,
        title: "¿Por qué el rendimiento de los suministros de las islas a reclamar es a veces mayor que mi rendimiento total?",
        desc: "Si tu clan ha conquistado una nueva isla, el rendimiento del proveedor para reclamar aumentará inmediatamente. Pero el rendimiento total no. "
      },
      {
        id: 11,
        title: "Si me uno/abandono un clan durante la guerra de clanes de la isla. ¿Qué coste tendrá?",
        desc: "Cuando te unes/abandonas un clan no podrás unirte a la guerra de clanes de la isla si ya ha comenzado y no recibirás ninguna recompensa por ello."
      },
      {
        id: 12,
        title: "如何增加岛屿争夺战中的胜率？",
        desc: "岛屿争夺战的胜者取决于你公会获得的分数。所以战力并不是决定胜利的唯一因素，努力也是。如果你的公会能集中使用朗姆酒（岛屿争夺战体力）并占领那些提供更多分数的岛屿将增加你的胜率。"
      },
      {
        id: 13,
        title: "¿Cómo puedo aumentar las posibilidades de ganar la guerra de clanes de la isla?",
        desc: "<p>Puedes utilizar los comentarios del juego para enviarnos un correo. El botón está en el menú de configuración del juego. Responderemos en un plazo de 24 horas, dependiendo de la zona horaria y otros efectos. También puedes ponerte en contacto con nosotros a través de los siguientes enlaces.</p><p>Facebook: <a href='https://www.facebook.com/NorthTowerGame'>https://www.facebook.com/NorthTowerGame</a></p><p>Twitter: <a href='https://twitter.com/NorthTowerGame'>https://twitter.com/NorthTowerGame</a></p>"
      },
      {
        id: 14,
        title: "He comprado accidentalmente un paquete equivocado en el juego. ¿Cómo puedo obtener un reembolso?",
        desc: "Puedes solicitar el reembolso al equipo de soporte de Google Play inmediatamente. Dentro de dos horas y no más de 48 horas después de la compra, por lo general, no causará un fallo en la solicitud de reembolso."
      },
      {
        id: 15,
        title: "He entrado al juego con el idioma equivocado. ¿Dónde puedo cambiarlo?",
        desc: "Puedes cambiar el idioma del juego en el menú de configuración. (La posición de entrada está en el círculo rojo de la imagen inferior).",
        img: [img151]
      },
      {
        id: 16,
        title: "Mi juego se ejecuta con lag o fallos.",
        desc: "La causa puede ser un problema de conexión o que tu móvil esté sobrecargado. Comprueba o cambia tu conexión. Puedes bajar el nivel de definición y efectos en el menú de configuración."
      },
      {
        id: 17,
        title: "¿Cómo puedo vincular mi cuenta con mi cuenta de Google y por qué debo hacerlo?",
        desc: "Puedes entrar al menú de configuración al terminar el tutorial. Es importante, porque si cambias de dispositivo o desinstalas el juego, puedes perder los datos de tu cuenta. Por favor, vincula tu cuenta para proteger tus datos del juego. O al menos recuerda tu ID de señor que aparece en el menú de configuración si no puedes vincular tu cuenta."
      },
      {
        id: 18,
        title: "Quiero cambiar la cuenta de Google vinculada a mi cuenta de juego o eliminar mi cuenta de juego para empezar de nuevo. ¿Qué debo hacer?",
        desc: "Puedes contactar con el equipo de soporte para que te ayude. Pero sólo a través de los comentarios del juego, porque tenemos que comprobar la autoridad de la cuenta."
      },
      {
        id: 19,
        title: "Compré un paquete en el juego, pero no lo recibí.",
        desc: "Suele ser debido a un retraso. Si todavía no recibes el paquete después de mucho tiempo, ponte en contacto con nuestro equipo de soporte con el número de factura de Google Play(GPA.XXX) para ayudar a hacer la comprobación."
      },
      {
        id: 20,
        title: "No puedo ver los anuncios para recibir mis recompensas.",
        desc: "Puede ser por varias razones. Por ejemplo, problemas de conexión, o que sean bloqueados por tu dispositivo. Algunas áreas no tienen suficientes proveedores de publicidad y puede causar que la publicidad no cargue. Puedes intentar usar una VPN para ayudarte a resolver el problema de carga o comprar el paquete de eliminación de anuncios para reclamar tus recompensas sin verlos."
      },
      {
        id: 21,
        title: "¿Hay alguna recompensa por reportar un error?",
        desc: "Por supuesto. Si el fallo del que has informado es confirmado, recibirás una recompensa en función de la severidad del fallo y de lo detallada que sea tu descripción. Informar del fallo con capturas de pantalla o vídeos será mejor."
      }
    ]
  }
}
export default message