import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import router from "@/router";
import '@/assets/font/font-adonals.css'
import "./assets/style/reset.scss"
import "./utils/rem"
import store from "./store";
Vue.config.productionTip = false

import i18n from './locales'
import { Local } from './locales/local'
// 支持小语种
Local(store.state.locale)
Vue.prototype.$Local = Local
const originalPush = Router.prototype.push
const langlist = {
  zh: '中文（简体）',
  zht: '中文（繁体）',
  en: 'English', // 英语
  de: 'Deutsh', // 德语
  pt: 'Português', // 葡萄牙语
  fr: 'Français', // 法语
  es: 'Español', // 西班牙语
  ja: '日本語', // 日语
  ko: "한국인", // 韩语
  it: 'Italiano', // 意大利语
  ru: "Русский", // 俄语
  th: 'ภาษาไทย', // 泰国语
  id: 'Indonesia', // 印尼语
  my: 'Melayu', // 马来西亚语
  vi: 'Tiếng Việt' // 越南语
}
Vue.prototype.$langlist = langlist
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')