<template>
  <div>
    <header class="pc-header">
      <div class="left" >
        <img @click="goPre" src="../assets/images/icon/left.png" alt="" v-show="this.activeMenu === 'faq'">
      </div>
      <div class="right">
        <span @click="showLang">
          {{langlist[curLang]}}
        </span>
        <span @click="showPopup">
          <van-icon class="menu-icon" name="coupon" />
        </span>
      </div>
    </header>
    <van-popup v-model="showMenu" position="right" :style="{ width: '50%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.2)', color: '#fff' }">
      <div class="menu">
        <span  :class="activeMenu === 'index' ? 'active' : ''" @click="goOther('index')">
          {{$t("index.pageName")}}
        </span>
        <span :class="activeMenu === 'shop' ? 'active' : ''" @click="goOther('shop')">
          {{$t("shop.pageName")}}
        </span>
        <span :class="activeMenu === 'faq' ? 'active' : ''" @click="goOther('faq')">
          {{$t("faq.pageName")}}
        </span>
      </div>
    </van-popup>
    <van-popup v-model="show" position="bottom" :style="{height: '30%', overflow: 'auto'}">
      <div class="lang-list">
        <div @click="changeLang(k)" v-for="(im, k) in langlist" :key="k" :class="curLang === k ?  'active' : ''">{{im}}</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { Icon, Popup } from 'vant';
export default {
  components: {
    [Icon.name]: Icon,
    [Popup.name]: Popup
  },
  data () {
    return {
      activeMenu: 'index',
      showMenu: false,
      show: false,
      curLang: 'en',
      langlist: this.$langlist
    }
  },
  watch: {
     '$route' () {
      this.activeMenu = this.$route.name
    }
  },
  mounted () {
    this.curLang = localStorage.getItem('user-lang-value') ? localStorage.getItem('user-lang-value') : 'en'
  },
  methods: {
    showPopup () {
      this.showMenu = true
    },
    showLang () {
      this.show = !this.show
    },
    changeLang (val) {
      this.curLang = val
      this.show = false
      localStorage.setItem('user-lang-value', val)
      this.$i18n.locale = val;
    },
    goOther (name) {
      this.activeMenu = name
      if (this.activeMenu === 'shop') {
        window.open("https://mall.northtowergame.com?language=" + this.$i18n.locale, "_blank");
      } else {
        this.showMenu = false
        this.$router.push('/' + name)
      }
    },
    // 跳转到上一个页面
    goPre () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .van-dropdown-menu {
  display: inline-block;
  font-size: 10px;
}
  .pc-header {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 40px;
    background: url('../assets/images/bg/hearder-bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    line-height: 32px;
    color: #fff;
    
    .left {
      position: relative;
      flex: 1;
      img {
        position: absolute;
        top: 16px;
        transform: translateY(-50%);
        height: 20px;
      }
    }
    .right {
      color: #fff;
      line-height: 32px;
      font-size:10px;
      span {
        padding-bottom: 10px;
        box-sizing: border-box;
        .menu-icon {
          margin: 0;
        }
      }
    }
  }
  .menu {
    font-size: 20px;
    span {
      display: block;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
    }
    span.active {
      color: rgb(191, 73, 73);
    }
  }
  .lang-list {
    width: auto;
    max-height: 100%;
    overflow: auto;
    background-color: #fff;
    z-index: 9999;
    box-sizing: border-box;
    padding: 0 10px;
    text-align: center;
    font-size: 8px;
    line-height: 40px;
    div {
      color: #cccccc;
      cursor: pointer;
    }
    div.active {
      color: rgb(191, 73, 73);
    }
  }
</style>