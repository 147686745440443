import img41 from '../../assets/images/faq/4-1.png'
import img51 from '../../assets/images/faq/5-1.png'
import img52 from '../../assets/images/faq/5-2.png'
import img151 from '../../assets/images/faq/15-1.png'
const message = {
  index: {
    pageName: 'ดัชนี',
    gamefeatures: 'ฟีเจอร์เกม'
  },
  shop: {
    pageName: 'ร้านค้า',
  },
  faq: {
    pageName: 'คำถามพบบ่อย',
    faqList: [
      {
        id: 1, 
        title: "ฉันรู้สึกเหนื่อยเวลาต้องใช้มังกรยักษ์เล็งและยิงด้วยตัวเอง มีวิธีที่สามารถทำให้เล่นได้ง่ายขึ้นบ้างไหม?",
        desc: 'มี คุณสามารถเลือกใช้การยิงแบบอัตโนมัติระหว่างการต่อสู้ในโหมดต่างๆ ได้ มันจะทำให้มังกรยักษ์ของคุณเลือกเป้าหมายโดยอัตโนมัติ และเปลี่ยนไปหาเป้าหมายใหม่เมื่อเป้าหมายเดิมถูกสังหารแล้ว คุณยังคงสามารถเลือกเป้าหมายได้ด้วยตัวเอง เพื่อจัดการกับศัตรูที่มีอันตรายสูง โดยจะไม่รบกวนการทำงานของระบบต่อสู้อัตโนมัติ แล้วอย่าลืมร่ายเวทมนตร์ด้วยล่ะ ซึ่งคุณยังจำเป็นต้องกดคำสั่งด้วยตัวเองอยู่ คุณสามารถปลดล็อกฟังก์ชันนี้ได้หลังจากเล่นด่าน "ดินแดนแห่งสายลม" โซนที่ 2 ด่านที่ 4 จบแล้ว'
      },
      {
        id: 2, 
        title: "มีฟังก์ชันอำนวยความสะดวกใดบ้าง ที่สามารถปลดล็อกเพื่อเพิ่มความเร็วในการเล่นให้ฉันได้?",
        desc: "นอกจากการยิงแบบอัตโนมัติ ซึ่งสามารถปลดล็อกได้จากรางวัลประจำด่านแล้ว คุณยังปลดล็อกฟังก์ชันอื่นๆ ส่วนใหญ่ได้จากของรางวัล VIP ประจำด่าน ซึ่งรวมถึงการท้าสู้รวดเร็วในโหมดปล้น การเร่งความเร็วต่อสู้ และการท้าสู้รวดเร็วในศึกบอสโลก "
      },
      {
        id: 3,
        title: 'ฉันได้เลือกเป้าหมายโจมตีไว้แล้ว แต่มังกรยักษ์ของฉันกลับไม่ทำดาเมจเลย',
        desc: "การโจมตีของมังกรยักษ์เป็นลักษณะการยิงแบบบ้าระห่ำ ดังนั้นในกรณีที่มีศัตรูตัวอื่นยืนขวางอยู่ด้านหน้าของเป้าหมาย ทำตัวเป็นเหมือนเครื่องโอบล้อม มันก็จะสกัดการโจมตีของมังกรยักษ์ แต่คุณสามารถแก้ปัญหานี้ได้ด้วยการเลือกใช้การโจมตีแบบเป็นวงกว้างเพิ่มขึ้น ซึ่งก็พอจะมีประโยชน์อยู"
      },
      {
        id: 4,
        title: "การกดปุ่มซื้อหอคอยสูงย้ำๆ ไม่ค่อยมีประสิทธิภาพ ฉันควรปรับปรุงอย่างไร?",
        desc: "คุณสามารถเลือกจำนวนดาวของหอคอยสูงที่คุณต้องการซื้อได้เลย โดยกดปุ่มดาวทางด้านล่างขวาในหน้าแก้ไขหอคอย จากนั้นกดเลือก หากคุณต้องการปลดล็อกดาวในระดับที่สูงยิ่งขึ้น คุณจำเป็นต้องมีหอคอยสูงที่มีดาวในระดับสูงเสียก่อน โดยคุณสามารถกดตรวจสอบเกณฑ์การปลดล็อกต่างๆ ได้ (ตำแหน่งทางเข้านี้อยู่ตรงวงกลมสีแดงในรูปด้านล่าง)",
        img: [
          img41
        ]
      },
      {
        id: 5,
        title: "ฉันมีหอคอยสูงที่ไม่ได้ใช้งานมากเกินไป ฉันจะจัดการกับพวกมันได้อย่างไร?",
        desc: "คุณสามารถจับพวกมันมารวมกันเพื่อทำให้เป็นหอคอยสูงที่มีคุณภาพดีขึ้นได้ โดยกดปุ่มรีไซเคิลที่อยู่ตรงกลางด้านขวาของหน้าแก้ไขหอคอยเพื่อเข้าสู่หน้ารวม กรณีที่คุณเลือกหอคอยสูง 3 แบบที่มีดาวแตกต่างกัน คุณก็จะเห็นเปอร์เซ็นต์ความเป็นไปได้ของผลลัพธ์ต่างๆ และคุณยังสามารถกดปุ่มเครื่องหมายคำถาม เพื่อดูคำแนะนำเพิ่มเติมได้ด้วย (ตำแหน่งทางเข้านี้อยู่ตรงวงกลมสีแดงในรูปด้านล่าง)",
        img: [
          img51,
          img52
        ]
      },
      {
        id: 6,
        title: "ทำไมฉันถึงแพ้ให้กับผู้เล่นที่ดูเหมือนจะอ่อนกว่าฉัน ไม่ว่าจะอยู่ในอารีน่าหรือศึกชิงเกาะกิลด์?",
        desc: "ผลลัพธ์การต่อสู้ไม่ได้ขึ้นอยู่กับตัวเลขของค่าพลังเพียงอย่างเดียว แต่ยังต้องดูองค์ประกอบของคุณและคู่ต่อสู้คนอื่นๆ ด้วย อย่างเช่น ผู้เล่นบางคนอาจเลือกที่จะเน้นการอัปเกรดเพียงไม่กี่ส่วน ซึ่งแม้โดยรวมอาจจะทำให้เขาดูอ่อน แต่มันกลับสร้างจุดแข็งให้สามารถโต้กลับผู้เล่นที่มีค่าพลังสูงๆ ได้"
      },
      {
        id: 701, // 补充的一条
        title: "เมื่อพบผู้เล่นที่โกงในอารีน่าหรือศึกบอสโลก ฉันควรทำอย่างไร?",
        desc: "คุณสามารถแจ้งร้องเรียนพวกเขามายังทีมสนับสนุนของเราได้ โดยตรวจสอบชื่อผู้เล่นที่โกงในหน้าบันทึกของอารีน่า หรือ ในหน้าอันดับของศึกบอสโลก หลังจากนั้นก็กด ”ร้องเรียน” เพื่อส่งจดหมายมาให้เรา หากเราสามารถยืนยันได้ถึงพฤติกรรมโกง ลำดับแรกจะมีการแจ้งเตือนไปยังผู้เล่นคนนั้นก่อน หากยังพบการโกงอีก บัญชีของเขาจะถูกแบนเป็นเวลา 3 วัน และบทลงโทษจะรุนแรงเพิ่มมากขึ้นหากยังตรวจพบการโกงซ้ำอีก"
      },
      {
        id: 7,
        title: "ฉันได้อัปเกรดพระราชวังของฉันแล้ว แต่การผลิตทรัพยากรกลับไม่เพิ่มขึ้น",
        desc: 'การอัปเกรดพระราชวังจะเพิ่มเพียงความจุในการเก็บทรัพยากรแต่ละชนิดเท่านั้น หากต้องการเพิ่มการผลิตทรัพยากร คุณสามารถทำได้โดยเล่นโหมด "ประตูแห่งความสิ้นหวัง" เพื่อให้ได้สิ่งก่อสร้างเพิ่มขึ้น หลังจากนั้นก็นำมาวางไว้บนเกาะเล็กของคุณแล้วทำการอัปเกรด'
      },
      {
        id: 8,
        title: "หัวหน้ากิลด์ของพวกเราไม่ได้ล็อกอินเข้าเกมมาหลายวันแล้ว ทำให้พวกเราไม่สามารถเริ่มเล่นศึกชิงเกาะกิลด์ได้",
        desc: "ณ ตอนนี้ หากหัวหน้ากิลด์ไม่ได้ล็อกอินเข้ามาเป็นเวลา 10 วัน เขาจะถูกขับออกจากกิลด์ไปโดยอัตโนมัติ และเรากำลังวางแผนพัฒนาระบบโหวตให้ขับออก เพื่อเป็นอีกทางในการแก้ไขปัญหานี"
      },
      {
        id: 9,
        title: "ฉันจะเสียความก้าวหน้าในค่ายฝึกหัดหรือไม่ หากฉันออกจากกิลด์ไป?",
        desc: "ไม่ เลเวลของค่ายฝึกหัดจะผูกติดอยู่กับตัวคุณเองโดยไม่เกี่ยวข้องกับกิลด์ และแต่ละคนจะมี ความก้าวหน้าเป็นของตัวเอง ทั้งนี้คุณจะสูญเสียบัฟต่างๆ จนกว่าคุณจะเข้าร่วมกิลด์ในครั้งต่อไป。"
      },
      {
        id: 10,
        title: "ทำไมอัตราผลผลิตของเกาะในบางครั้งถึงสูงกว่าอัตราผลผลิตโดยรวมของฉัน?",
        desc: "หากกิลด์ของคุณสามารถครอบครองเกาะใหม่ได้ อัตราผลผลิตของเกาะก็จะเพิ่มขึ้นทันที แต่อัตราผลผลิตโดยรวมจะไม่เพิ่มขึ้น"
      },
      {
        id: 11,
        title: "หากฉันเข้าร่วม/ออกจากกิลด์ขณะยังอยู่ในศึกชิงเกาะกิลด์ ฉันจะสูญเสียอะไร?",
        desc: "หากคุณเข้าร่วม/ออกจากกิลด์ระหว่างที่ศึกชิงเกาะกิลด์ได้เริ่มต้นไปแล้ว จะถือว่าคุณไม่มีส่วนร่วมและเป็นผลให้คุณไม่ได้รับรางวัล"
      },
      {
        id: 12,
        title: "ทำอย่างไรถึงจะเพิ่มโอกาสในการชนะศึกชิงเกาะกิลด์ได้?",
        desc: "ผู้ชนะในศึกชิงเกาะกิลด์จะได้รับการตัดสินจากคะแนนที่กิลด์ของคุณทำได้ ดังนั้นนอกจากค่าพลังแล้ว ความพยายามก็เป็นอีกองค์ประกอบสำคัญในการช่วงชิงชัยชนะ ยกตัวอย่างเช่น คุณจะมีโอกาสชนะมากขึ้น หากกิลด์ของคุณเน้นไปที่การใช้รัม (ค่าความทรหดในศึกชิงเกาะกิลด์) และครอบครองเกาะที่ให้คะแนนสูงขึ้น"
      },
      {
        id: 13,
        title: "หากพบปัญหา ฉันจะสามารถติดต่อกับทีมสนับสนุนเกมได้อย่างไร และใช้เวลานานเท่าไรในการได้รับคำตอบ?",
        desc: "<p>ภายในเมนูการตั้งค่าเกม จะมีปุ่มข้อเสนอแนะอยู่ คุณสามารถกดปุ่มนี้เพื่อส่งจดหมายหาเราได้ โดยเราจะทำการตอบกลับคุณภายใน 24 ชั่วโมง ขึ้นอยู่กับเขตเวลาและข้อจำกัดอื่นๆ และคุณยังสามารถติดต่อเราได้ผ่านช่องทางอื่นอีกดังต่อไปนี้</p><p>Facebook: <a href='https://www.facebook.com/NorthTowerGame'>https://www.facebook.com/NorthTowerGame</a></p><p>Twitter: <a href='https://twitter.com/NorthTowerGame'>https://twitter.com/NorthTowerGame</a></p>"
      },
      {
        id: 14,
        title: "หากฉันบังเอิญซื้อแพ็กในเกมผิดไป จะสามารถขอเงินคืนได้อย่างไร?",
        desc: "คุณสามารถร้องขอไปยังทีมสนับสนุนของ Google Play เพื่อขอรับเงินคืนได้ทันที ซึ่งปกติแล้ว หากเป็นการซื้อภายใน 2 ถึง 48 ชั่วโมง การส่งคำร้องขอเงินคืนจะสามารถดำเนินการได"
      },
      {
        id: 15,
        title: "หากฉันเข้าเกมไปโดยเลือกภาษาผิด จะสามารถเปลี่ยนภาษาได้ที่ไหน?",
        desc: "คุณสามารถเปลี่ยนภาษาภายในเกมได้ที่เมนูการตั้งค่า (ตำแหน่งทางเข้านี้อยู่ตรงวงกลมสีแดงในรูปด้านล่าง)",
        img: [img151]
      },
      {
        id: 16,
        title: "เกมของฉันทำงานช้าหรือมีข้อบกพร่อง",
        desc: "ปัญหาอาจเกิดจากการเชื่อมต่อ หรือโทรศัพท์มือถือของคุณกำลังทำงานหนัก กรุณาตรวจสอบหรือเปลี่ยนการเชื่อมต่อของคุณ คุณสามารถปรับลดความละเอียดของภาพ และเอฟเฟกต์ต่างๆ ให้น้อยลงได้ในเมนูการตั้งค่า"
      },
      {
        id: 17,
        title: "ฉันสามารถผูกบัญชีเกมกับบัญชี Google ได้อย่างไร และทำไมฉันจึงควรทำเช่นนั้น?",
        desc: "หลังจากที่คุณเล่นโหมดการสอนจบแล้ว คุณสามารถเข้าสู่เมนูการตั้งค่าเพื่อผูกบัญชีได้  สิ่งนี้สำคัญ เพราะในกรณีที่คุณเปลี่ยนอุปกรณ์หรือถอนการติดตั้งเกมไป คุณอาจจะสูญเสียข้อมูลในบัญชี การผูกบัญชีไว้จึงจะช่วยเก็บรักษาข้อมูลเกมของคุณ หรือในกรณีที่คุณไม่สามารถผูกบัญชีได้ คุณก็ควรจดจำ ID ลอร์ดของคุณที่แสดงในเมนูการตั้งค่า "
      },
      {
        id: 18,
        title: "หากฉันต้องการเปลี่ยนบัญชี Google ที่ผูกไว้กับบัญชีเกม หรือลบบัญชีเกมทิ้งแล้วเริ่มต้นใหม่ ฉันควรทำอย่างไร?",
        desc: "คุณสามารถติดต่อทีมสนับสนุนให้ช่วยเหลือได้ แต่ต้องดำเนินการผ่านช่องทางข้อเสนอแนะภายในเกมเท่านั้น เพราะเราจำเป็นต้องตรวจสอบสิทธิ์อนุญาตระหว่างคุณกับบัญชี"
      },
      {
        id: 19,
        title: "ฉันซื้อแพ็กในเกมแล้วแต่ไม่เห็นได้รับ",
        desc: "ความล่าช้ามักเกิดขึ้นได้ กรณีที่คุณไม่ได้รับแพ็กเป็นเวลานาน คุณสามารถติดต่อทีมสนับสนุนของเรา โดยแจ้งหมายเลขใบแจ้งหนี้ของ Google Play (GPA.xxx) เพื่อทำการตรวจสอบ"
      },
      {
        id: 20,
        title: "ฉันไม่สามารถดูโฆษณาเพื่อรับรางวัลได้",
        desc: "ปัญหานี้อาจเกิดขึ้นได้จากหลายสาเหตุ โดยอาจเป็นปัญหาที่มาจากการเชื่อมต่อ หรือเป็นการบล็อกโฆษณาจากอุปกรณ์ของคุณเอง หรือในบางพื้นที่มีผู้ให้บริการโฆษณาไม่เพียงพอ ก็อาจทำให้โฆษณาไม่ถูกโหลดขึ้นมาได้ คุณสามารถลองใช้ VPN เพื่อช่วยแก้ปัญหาการโหลด หรือซื้อแพ็กลบโฆษณาถาวร เพื่อจัดการรับรางวัลโดยไม่ต้องดูโฆษณาเลยก็ได้"
      },
      {
        id: 21,
        title: "การรายงานบั๊กจะมีรางวัลให้หรือไม่?",
        desc: "แน่นอน ถ้าบั๊กที่คุณรายงานมาได้รับการยืนยัน คุณจะได้รับรางวัลตามผลกระทบของบั๊กและรายละเอียดที่คุณได้แจ้งมา ดังนั้นการรายงานบั๊กด้วยรูปภาพจากหน้าจอหรือวิดีโอจึงเป็นสิ่งที่ดีกว่า"
      }
    ]
  }
}
export default message