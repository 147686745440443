<template>
  <div>
    <header class="pc-header">
      <div class="left" >
        <img @click="goPre" src="../assets/images/icon/left.png" alt="" v-show="this.activeMenu === 'faq'">
      </div>
      <div class="right">
        <span :class="activeMenu === 'index' ? 'active' : ''" @click="goOther('index')">
           {{$t("index.pageName")}}
        </span>
        <span :class="activeMenu === 'shop' ? 'active' : ''" @click="goOther('shop')">
          {{$t("shop.pageName")}}
        </span>
        <span :class="activeMenu === 'faq' ? 'active' : ''" @click="goOther('faq')">
           {{$t("faq.pageName")}}
        </span>
        <span class="lang-title" @click="showLang">
          {{langlist[curLang]}}
        </span>
        <div v-if="show" :class="show ? 'lang-list lang-list-active' : 'lang-list'">
            <div @click="changeLang(k)" v-for="(im, k) in langlist" :key="k" :class="curLang === k ?  'active' : ''">{{im}}</div>
          </div>
      </div>
    </header>
  </div>
</template>
<script>
import { Icon, DropdownMenu, DropdownItem } from 'vant';
export default {
  components: {
    [Icon.name]: Icon,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem
  },
  data () {
    return {
      activeMenu: 'index',
      show: false,
      curLang: 'en',
      langlist: this.$langlist
    }
  },
  watch: {
     '$route' () {
      this.activeMenu = this.$route.name
    }
  },
  mounted () {
    this.curLang = localStorage.getItem('user-lang-value') ? localStorage.getItem('user-lang-value') : 'en'
    this.$i18n.locale = this.curLang;
  },
  methods: {
    showLang () {
      this.show = !this.show
    },
    changeLang (val) {
      this.curLang = val
      this.show = false
      this.curLang = val
      localStorage.setItem('user-lang-value', val)
      this.$i18n.locale = val;
    },
    goOther (name) {
      this.activeMenu = name
      if (this.activeMenu === 'shop') {

        window.open("https://mall.northtowergame.com?language=" + this.$i18n.locale, "_blank");
      } else {
        this.showMenu = false
        this.$router.push('/' + name)
      }
    },
    // 跳转到上一个页面
    goPre () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .van-dropdown-menu {
  display: inline-block;
  font-size: 10px;
}
  .pc-header {
    position: fixed;  
    z-index: 999;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
    margin: 0;
    width: 100%;
      // max-width: 2rem;
    height: 20px;
    background: url('../assets/images/bg/hearder-bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    line-height: 16px;
    color: #fff;
    .left {
      position: relative;
      flex: 1;
      img {
        position: absolute;
        top: 8px;
        transform: translateY(-50%);
        height: 10px;
      }
    }
    .right {
      color: #fff;
      line-height: 16px;
      font-size: 10px;
      span {
        font-size: 8px;
        text-stroke: 0.5px #000;
        -webkit-text-stroke: 0.5px #000;
        margin-right: 10px;
        cursor: pointer;
      }
      span.active {
        font-size: 10px;
        text-shadow: 1px 1px 1px #000000;
      }
      .lang-title {
        display: inline-block;
        cursor: pointer;
        font-size: 4px;
      }
      .lang-list {
          width: auto;
          max-height: 100px;
          overflow: auto;
          position: absolute;
          top: 16px;
          right: 12px;
          background-color: #fff;
          border: 0.5px solid #ededed;
          z-index: 9999;
          box-sizing: border-box;
          padding: 0 10px;
          text-align: center;
          font-size: 4px;
          div {
            // font-size: 8px;
            color: #cccccc;
            cursor: pointer;
          }
          div.active {
            color: rgb(191, 73, 73);
          }
        }
    }
  }
</style>