import img41 from '../../assets/images/faq/4-1.png'
import img51 from '../../assets/images/faq/5-1.png'
import img52 from '../../assets/images/faq/5-2.png'
import img151 from '../../assets/images/faq/15-1.png'
const message = {
  index: {
    pageName: 'Mục lục',
    gamefeatures: 'Tính năng game'
  },
  shop: {
    pageName: 'Cửa Hàng',
  },
  faq: {
    pageName: 'Câu hỏi thường gặp',
    faqList: [
      {
        id: 1, 
        title: "Việc rồng nhắm bắm làm tôi mệt mỏi. Có cách chơi nào dễ hơn không?",
        desc: "Có. Bạn có thể chọn dùng chế độ tự động chiến đấu trong nhiều chế độ chiến đấu. Điều này sẽ khiến chú rồng của bạn tự động chọn mục tiêu, và thay đổi khi mục tiêu bị tiêu diệt. Bạn cũng có thể tự chọn mục tiêu nhằm đối phó với những kẻ địch có mức độ đe dọa cao và nó sẽ không làm gián đoạn chế độ tự động chiến đấu. Đừng quên sử dụng phép thuật, vì bạn vẫn cần điều khiển chúng thủ công. Bạn có thể mở khóa tính năng này sau khi hoàn thành khu vực Vùng Đất Hoang Dã 2 cấp 4."
      },
      {
        id: 2, 
        title: "Có thể mở khóa tính năng tiện lợi nào để tăng tốc lối chơi của tôi không?",
        desc: "Ngoài việc mở khóa tự động chiến đấu từ phần thưởng cấp độ. Bạn có thể mở khóa hầu hết những tính năng khác từ phần thưởng cấp VIP. Bao gồm thử thách cướp bóc nhanh, tăng tốc độ chiến đấu và khiêu chiến nhanh Boss Thế Giới."
      },
      {
        id: 3,
        title: 'Tôi đã chọn mục tiêu nhưng rồng của tôi lại không gây bất kỳ sát thương nào lên nó.',
        desc: "Đòn tấn công của rồng thực tế là có đường đạn. Vì vậy, nếu có kẻ địch khác (như máy móc vây hãm) trước mục tiêu, thì nó sẽ chặn đòn tấn công từ chú rồng của bạn. Để xử lý vấn đề này, thử sử dụng thêm các đòn tấn công AOE có thể sẽ hữu dụng."
      },
      {
        id: 4,
        title: "Tháp từ nút mua hiệu quả rất thấp. Tôi phải cải thiện thế nào?",
        desc: "Bạn có thể chọn những ngôi sao của tòa tháp muốn mua. Nhấp nút dấu sao dưới cùng bên phải trên trang chỉnh sửa tháp, sau đó bạn có thể lựa chọn. Những ngôi sao cao hơn sẽ cần bạn phải mở khóa bằng cách vượt qua ngọn tháp nhiều sao. Bạn có thể nhấp vào để tra cứu yêu cầu mở khóa. (Lối vào là ở vòng tròn đỏ trong tấm hình dưới đây)",
        img: [
          img41
        ]
      },
      {
        id: 5,
        title: "Tôi có quá nhiều tháp vô dụng. Tôi phải làm gì với chúng?",
        desc: "Bạn có thể tái sử dụng chúng bằng cách ghép chúng thành một tháp phẩm chất cao hơn. Nhấp nút có dấu tái sử dụng ở giữa bên phải trang chỉnh sửa tháp để vào trang ghép. Bạn có thể nhìn thấy xác suất mỗi kết quả nếu bạn chọn 3 tòa tháp có số sao khác nhau. Bạn có thể nhấp nút dấu hỏi để xem thêm hướng dẫn. (Lối vào là ở vòng tròn đỏ trong tấm hình dưới đây)",
        img: [
          img51,
          img52
        ]
      },
      {
        id: 6,
        title: "Tại sao trên đấu trường hoặc đảo liên minh những người chơi yếu hơn tôi lại có thể đánh bại được tôi",
        desc: "Kết quả chiến đấu không chỉ phụ thuộc vào chỉ số sức mạnh. Nó còn bị ảnh hưởng bởi việc kết hợp giữa bạn và những người chơi khác. Ví dụ, một số người chơi được đầu tư nhiều vào một số phương diện trong đội của họ có thể khiến họ trông thì yếu nhưng lại có đủ sức mạnh để chống lại những người chơi mạnh hơn."
      },
      {
        id: 701, // 补充的一条
        title: "Tôi phải đối phó thế nào với người chơi gian lận trên đấu trường hoặc boss thế giới?",
        desc: 'Bạn có thể báo cáo họ cho nhóm hỗ trợ của chúng tôi. Bạn có thể tìm thấy người chơi gian lận trên trang nhật ký trong đấu trường và trên trang xếp hạng trong boss thế giới. Sau đó nhấp "báo cáo" để gửi mail cho chúng tôi. Nếu chúng tôi xác nhận hành vi gian lận, chúng tôi trước tiên sẽ cảnh cáo người chơi gian lận. Nếu tiếp tục gian lận, họ sẽ bị khóa tài khoản 3 ngày. Và hình phạt sẽ tiếp tục tăng lên nếu người đó không hối cải.'
      },
      {
        id: 7,
        title: "Tôi đã nâng cấp cung điện của mình nhưng sản lượng tài nguyên thì lại không tăng.",
        desc: "Nâng cấp cung điện sẽ chỉ tăng khả năng lưu trữ của mỗi tài nguyên. Để tăng sản lượng tài nguyên, bạn phải chơi chế độ cổng chế độ để kiếm thêm những tòa nhà có thể đặt trên những hòn đảo của bạn và nâng cấp chúng."
      },
      {
        id: 8,
        title: "Lãnh đạo liên minh chúng tôi đã không đăng nhập game vài ngày và chúng tôi không thể vào chiến tranh đảo liên minh.",
        desc: "Giờ đây, lãnh đạo liên minh nếu không đăng nhập trong 10 ngày thì sẽ tự động bị đá ra. Chúng tôi đang lên kế hoạch lập hệ thống bỏ phiếu đá bỏ để cải thiện vấn đề này."
      },
      {
        id: 9,
        title: "Liệu tiến độ của tôi trong trại huấn luyện có bị mất đi khi tôi thoát khỏi liên minh?",
        desc: "Không. Cấp trại liên minh được liên kết với bạn chứ không phải liên minh và bất kỳ ai khác. Chúng có tiến độ riêng. Nhưng bạn sẽ mất buff của nó cho tới khi bạn gia nhập liên minh tiếp theo."
      },
      {
        id: 10,
        title: "Sao sản lượng cung ứng yêu cầu của những đảo đôi khi lại cao hơn tổng sản lượng của tôi?",
        desc: "Nếu liên minh của bạn đã chinh phục được một hòn đảo mới, thì yêu cầu sản lượng cung ứng cũng lập tức tăng. Nhưng tổng sản lượng thì không."
      },
      {
        id: 11,
        title: "Cái giá phải trả nếu tôi gia nhập/rời khỏi liên minh trong thời gian chiến tranh đảo liên minh là gì?",
        desc: "Khi bạn gia nhập/rời khỏi liên minh thì bạn sẽ không thể tham gia chiến tranh đảo liên minh nếu bạn đã bắt đầu và không nhận thêm bất kỳ phần thưởng nào từ nó nữa."
      },
      {
        id: 12,
        title: "Làm thế nào để tăng cơ hội chiến thắng trong chiến tranh đảo liên minh?",
        desc: "Số điểm liên minh bạn nhận được sẽ quyết định ai chiến thắng trong chiến tranh đảo liên minh. Vì vậy, sức mạnh không phải là yếu tố duy nhất để giành chiến thắng mà là sự nỗ lực. Nếu liên minh của bạn có thể tập trung vào việc sử dụng Rum (thể lực chiến tranh đảo liên minh) và chinh phục những hòn đảo mang lại nhiều điểm số hơn, thì cơ hội chiến thắng của bạn cũng sẽ tăng."
      },
      {
        id: 13,
        title: "Nếu có vấn đề thì tôi phải làm thế nào để liên hệ với nhóm hỗ trợ game? Bao lâu tôi mới nhận được hồi âm?",
        desc: "<p>Bạn có thể sử dụng tính năng phản hồi trong game để mail cho chúng tôi. Nút này ở trong menu thiết lập game. Chúng tôi sẽ hồi âm bạn trong 24 giờ, dựa vào múi giờ của bạn và những yếu tố khác. Bạn còn có thể liên hệ với chúng tôi thông qua cách sau:</p><p>Facebook: <a href='https://www.facebook.com/NorthTowerGame'>https://www.facebook.com/NorthTowerGame</a></p><p>Twitter: <a href='https://twitter.com/NorthTowerGame'>https://twitter.com/NorthTowerGame</a></p>"
      },
      {
        id: 14,
        title: "Tôi đã vô tình mua nhầm gói trong game. Vậy phải làm sao để được hoàn tiền?",
        desc: "Bạn có thể hỏi nhóm hỗ trợ Google Play để được hoàn tiền lập tức. Bạn thường sẽ được hoàn tiền nếu sau khi mua trong 2 giờ và không quá 48 giờ."
      },
      {
        id: 15,
        title: "Tôi thiết lập nhầm ngôn ngữ vào game. Làm sao để thay đổi?",
        desc: "Bạn có thể thay đổi ngôn ngữ của game trong menu thiết lập. (Lối vào là ở vòng tròn đỏ trong tấm hình dưới đây)",
        img: [img151]
      },
      {
        id: 16,
        title: "Game của tôi bị lag hoặc lỗi.",
        desc: "Lỗi kết nối có thể khiến điện thoại của bạn đang trong trạng thái quá tải. Vui lòng kiểm tra hoặc thay đổi kết nối của bạn. Bạn có thể giảm độ nét và hiệu ứng trong menu thiết lập."
      },
      {
        id: 17,
        title: "Làm thế nào để liên kết tài khoản của tôi với tài khoản Google và tại sao tôi phải làm vậy?",
        desc: "Bạn có thể vào menu thiết lập sau khi hoàn thành một vài hướng dẫn. Điều quan trọng là nếu bạn chuyển đổi thiết bị hoặc xóa game, bạn có thể mất dữ liệu trong tài khoản của mình. Vì vậy, bạn hãy liên kết tài khoản của bạn để bảo vệ dữ liệu game của mình. Hoặc nếu bạn không thể liên kết tài khoản của mình, thì ít nhất hãy nhớ ID hiển thị trên menu thiết lập."
      },
      {
        id: 18,
        title: "Tôi muốn thay đổi tài khoản Google đã liên kết với tài khoản game của mình hoặc xóa tài khoản game của tôi để bắt đầu lại. Vậy tôi phải làm gì?",
        desc: "Bạn có thể liên hệ với nhóm hỗ trợ của chúng tôi để hỗ trợ. Nhưng phải thông qua liên hệ phản hồi trong game, bởi chúng tôi cầm kiểm tra quyền hạn giữa bạn và tài khoản."
      },
      {
        id: 19,
        title: "Tôi đã mua gói trong game nhưng lại không nhận được.",
        desc: "Điều này có thể là do trễ. Nếu bạn vẫn không nhận được gói trong thời gian dài. Vui lòng liên hệ với nhóm hỗ trợ của chúng tôi với mã số đơn Google Play (GPA.XXX) để được hỗ trợ kiểm tra."
      },
      {
        id: 20,
        title: "Tôi không thể xem quảng cáo để lĩnh thưởng",
        desc: "Điều này có thể do một vài lý do, ví dụ như lỗi kết nối hoặc thiết bị của bạn có thể đã chặn nó. Và nếu một số khu vực không có đủ nhà cung cấp quảng cáo thì quảng cáo sẽ không thể tải về. Bạn có thể thử dùng VPN để giải quyết lỗi tải hoặc mua gói bỏ quảng cáo để lĩnh thưởng mà không cần xem chúng. "
      },
      {
        id: 21,
        title: "Báo cáo bug thì có phần thưởng nào không?",
        desc: "Đương nhiên. Nếu bug mà bạn báo cáo cho chúng tôi được xác nhận. Thì bạn sẽ nhận được phần thưởng dựa vào ảnh hưởng của bug đó và chi tiết bạn cung cấp ra sao. Vì vậy báo cáo bug với ảnh cắt màn hình hoặc video sẽ tốt hơn."
      }
    ]
  }
}
export default message