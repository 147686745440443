import img41 from '../../assets/images/faq/4-1.png'
import img51 from '../../assets/images/faq/5-1.png'
import img52 from '../../assets/images/faq/5-2.png'
import img151 from '../../assets/images/faq/15-1.png'
const message = {
  index: {
    pageName: 'INDEX',
    gamefeatures: 'FONCTIONNALITÉS DU JEU'
  },
  shop: {
    pageName: 'BOUTIQUE',
  },
  faq: {
    pageName: 'FAQ',
    faqList: [
      {
        id: 1, 
        title: "La visée manuelle du dragon me fatigue. Existe-t-il un moyen plus simple de jouer ?",
        desc: "Oui. Vous pouvez choisir d'utiliser le combat automatique pendant plusieurs types de modes de combat. Cela permettra à votre dragon de choisir automatiquement la cible et de changer lorsque la cible est tuée. Vous pourrez toujours choisir vous-même la cible pour faire face aux ennemis à haut risque et cela n'interrompra pas le mode de combat automatique. N'oubliez pas de lancer les sorts, ils ont encore besoin que vous les contrôliez manuellement. Vous pouvez déverrouiller cette fonction après avoir terminé le stage 4 de la zone 2 de Windland."
      },
      {
        id: 2, 
        title: "Quelle fonction pratique peut être déverrouillée pour accélérer mon gameplay ?",
        desc: "À part le combat automatique qui peut être débloqué à partir des récompenses de niveau, vous pouvez débloquer la plupart des autres fonctions à partir des récompenses de niveau VIP, y compris le défi rapide de pillage, l'accélération de combat et le défi rapide de boss mondial."
      },
      {
        id: 3,
        title: "J'ai choisi la cible, mais mon dragon ne lui inflige aucun dégât.",
        desc: "L'attaque du dragon est balistique. Donc, s'il y a un autre ennemi (comme une machine de siège) devant la cible, il bloquera l'attaque de votre dragon. Pour gérer ce problème, essayer d'utiliser plus d'attaques de zone pourrait être utile."
      },
      {
        id: 4,
        title: "Spammer le bouton d'achat de la tour est peu efficace. Comment puis-je améliorer ?",
        desc: "Vous pouvez choisir les étoiles de la tour que vous souhaitez acheter. Appuyez sur le bouton d’étoile en bas à droite du menu de modification de la tour pour pouvoir choisir. Les étoiles de départ plus élevées vous obligent à déverrouiller en obtenant une tour d'étoiles élevées. Vous pouvez appuyer pour vérifier les exigences de déverrouillage. (L’accès est le point rouge sur l'image ci-dessous)",
        img: [
          img41
        ]
      },
      {
        id: 5,
        title: "J'ai trop de tours inutiles. Comment puis-je les gérer ?",
        desc: "Vous pouvez les recycler en les combinant en une tour de meilleure qualité. Appuyez sur le bouton de marque de recyclage au milieu à droite du menu de modification de la tour pour accéder au menu de combinaison. Vous pouvez voir la probabilité de chaque résultat si vous choisissez trois tours qui ont des étoiles différentes. Et vous pouvez appuyer sur le bouton de point d'interrogation pour consulter plus de guides. (L’accès est le point rouge sur l'image ci-dessous)",
        img: [
          img51,
          img52
        ]
      },
      {
        id: 6,
        title: "Pourquoi suis-je parfois vaincu par des joueurs plus faibles que moi dans l'arène ou dans la guerre des îles de ligue ?",
        desc: "Le résultat du combat ne dépend pas seulement de la quantité de puissance. Il sera également affecté par les combinaisons entre vous et les autres joueurs. Par exemple, certains joueurs très investis dans quelques parties de leur équipe peuvent les faire paraître faibles, mais avoir la force de contrer des joueurs plus puissants."
      },
      {
        id: 701, // 补充的一条
        title: "Comment dois-je gérer un joueur tricheur dans l'arène ou le boss mondial ?",
        desc: "Vous pouvez le signaler à notre équipe d'assistance. Vous pouvez trouver le joueur tricheur dans le menu de journal de l'arène et dans le menu de classement du boss mondial. Appuyez ensuite sur « Signaler » pour nous envoyer un e-mail. Si nous confirmons un comportement de triche, nous avertissons d'abord le joueur tricheur. S'il continue à tricher, il recevra 3 jours de bannissement de compte. Et la punition continuera d'augmenter s'il ne se repent pas."
      },
      {
        id: 7,
        title: "J'ai amélioré mon palais, mais ma production de ressources n'a pas augmenté.",
        desc: "L’amélioration du palais ne fera qu'augmenter la capacité de stockage de chaque ressource. Pour augmenter la production de ressources, vous devez jouer au mode « Portail de l’impasse » pour gagner plus de bâtiments pouvant être placés sur vos îles et les améliorer."
      },
      {
        id: 8,
        title: "Notre chef de ligue ne s'est pas connecté au jeu depuis plusieurs jours et nous ne pouvons pas lancer la guerre des îles de la ligue.",
        desc: "Pour le moment, le chef de ligue sera automatiquement expulsé après dix jours sans connexion. Nous prévoyons un système d’expulsion par vote pour améliorer ce problème."
      },
      {
        id: 9,
        title: "Ma progression dans le camp d'entraînement est-elle perdue lorsque je quitte la ligue ?",
        desc: "Non. Le niveau du camp d'entraînement vous est lié, et non à la ligue, et tout le monde possède sa propre évolution. Mais vous perdrez les buffs jusqu'à ce que vous rejoigniez une nouvelle ligue."
      },
      {
        id: 10,
        title: "Pourquoi le rendement d’approvisionnement des îles à réclamer est-il parfois supérieur à mon rendement total ?",
        desc: "Si votre ligue a conquis de nouvelles îles, le rendement d’approvisionnement à réclamer augmentera immédiatement. Mais le rendement total ne le fera pas."
      },
      {
        id: 11,
        title: "Si je rejoins ou quitte une ligue pendant la guerre des îles de ligue. Quel en sera le coût ?",
        desc: "Lorsque vous rejoignez ou quittez une ligue, vous ne pourrez pas rejoindre la guerre des îles de ligue si elle a déjà commencé et vous ne recevrez aucune récompense de cette dernière."
      },
      {
        id: 12,
        title: "Comment augmenter les chances de gagner la guerre des îles de ligue ?",
        desc: "Le vainqueur de la guerre des îles de ligue est déterminé par le score obtenu par votre ligue. La puissance n'est donc pas le seul élément de Victoire, mais l'effort. Si votre ligue peut se concentrer sur l'utilisation de Rhum (l’endurance de guerre des îles de ligue) et conquérir les îles qui fournissent plus de scores, cela augmentera vos chances de gagner."
      },
      {
        id: 13,
        title: "Comment puis-je contacter l'équipe d'assistance du jeu si je rencontre un problème ? Combien de temps avant de recevoir une réponse ?",
        desc: "<p>Vous pouvez utiliser le feedback en jeu pour nous envoyer un e-mail. Le bouton se trouve dans le menu des paramètres du jeu. Nous vous répondrons dans les 24 heures, en fonction du fuseau horaire et d'autres raisons. Vous pouvez également nous contacter par les moyens ci-dessous.</p><p>Facebook: <a href='https://www.facebook.com/NorthTowerGame'>https://www.facebook.com/NorthTowerGame</a></p><p>Twitter: <a href='https://twitter.com/NorthTowerGame'>https://twitter.com/NorthTowerGame</a></p>"
      },
      {
        id: 14,
        title: "J'ai accidentellement acheté le mauvais pack dans le jeu. Comment puis-je obtenir un remboursement ?",
        desc: "Vous pouvez demander un remboursement immédiat à l'équipe d'assistance de Google Play. Dans les deux heures et pas plus de 48 heures après l'achat, votre demande de remboursement n'échouera généralement pas."
      },
      {
        id: 15,
        title: "Je suis entré dans le jeu avec la mauvaise langue. Où puis-je le changer ?",
        desc: "Vous pouvez changer la langue de votre jeu dans le menu des paramètres. (L’accès est le cercle rouge sur l'image ci-dessous)",
        img: [img151]
      },
      {
        id: 16,
        title: " Mon jeu tourne avec du lag ou des bugs.",
        desc: "Un problème de connexion peut en être la cause ou votre téléphone portable est en surcharge. Veuillez vérifier ou modifier votre connexion. Vous pouvez baisser le niveau de définition et des effets dans le menu des paramètres."
      },
      {
        id: 17,
        title: "Comment dois-je lier mon compte à mon compte Google et pourquoi dois-je le faire ?",
        desc: "Vous pouvez accéder au menu des paramètres après avoir terminé quelques tutoriels. C'est important car si vous changez d'appareil ou désinstallez le jeu, vous risquez de perdre les données de votre compte. Veuillez lier votre compte pour protéger vos données de jeu. Ou au moins rappelez-vous de votre ID de seigneur affiché dans le menu des paramètres si vous ne pouvez pas lier votre compte."
      },
      {
        id: 18,
        title: "Je souhaite modifier le compte Google associé à mon compte de jeu ou supprimer mon compte de jeu pour recommencer. Comment faire ?",
        desc: "Vous pouvez contacter l'équipe d'assistance pour vous aider. Mais uniquement via le feedback en jeu, car nous devons vérifier votre possession du compte."
      },
      {
        id: 19,
        title: "J'ai acheté un pack dans le jeu mais je ne l'ai pas reçu.",
        desc: "C'est généralement causé par un retard. Si vous ne recevez toujours pas le pack pendant une longue période, veuillez contacter notre équipe d'assistance avec le numéro de facture Google Play (GPA.XXX) pour aider à la vérification."
      },
      {
        id: 20,
        title: "Je ne peux pas regarder les publicités pour réclamer mes récompenses.",
        desc: "Cela peut être dû à plusieurs raisons, comme des problèmes de connexion ou votre appareil peut les bloquer. Et si certaines régions n'ont pas assez de fournisseurs de publicité, la publicité ne peut pas être chargée. Vous pouvez essayer d'utiliser un VPN pour vous aider à résoudre les problèmes de chargement ou acheter un pack de suppression des publicités pour réclamer vos récompenses sans les regarder."
      },
      {
        id: 21,
        title: "Y a-t-il une récompense pour le signalement de bugs ?",
        desc: "Bien sûr. Si le bug que vous avez signalé a été confirmé, vous obtiendrez des récompenses en fonction de l'effet du bug et du degré de détail de votre description. Donc, signaler un bug avec des captures d'écran ou des vidéos est le mieux."
      }
    ]
  }
}
export default message